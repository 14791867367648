export default {
  createdVideoFor: '<b>{name} ਲਈ {celebrity}<b/> ਦੁਆਰਾ ਇੱਕ ਵੀਡੀਓ ਬਣਾਓ',
  by: 'ਨਾਲ',
  share: 'ਸਾਂਝਾ ਕਰੋ',
  others: 'ਹੋਰ',
  and: 'ਅਤੇ',
  videoFor: '{name} ਲਈ',
  priceDetails: 'ਕੀਮਤ ਵੇਰਵੇ',
  costPrice: 'ਲਾਗਤ ਮੁੱਲ',
  discount: 'ਛੂਟ',
  walletUsed: 'ਵਾਲਿਟ ਵਰਤਿਆ',
  totalAmount: 'ਕੁੱਲ ਮਾਤਰਾ',
  download: 'ਡਾ .ਨਲੋਡ',
  starOfTheWeek: 'ਹਫ਼ਤੇ ਦਾ ਤਾਰਾ',
  makeVideo: 'ਇੱਕ ਵੀਡੀਓ ਬਣਾਓ',
  aboutMe: 'ਮੇਰੇ ਬਾਰੇ ਵਿੱਚ',
  photo: 'ਤਸਵੀਰ',
  Instructions: 'ਵੀਡੀਓ ਨੂੰ ਬੇਨਤੀ ਕਰਨ ਲਈ ਨਿਰਦੇਸ਼',
  requestDetailInfo1: 'ਆਪਣੇ ਸੰਦੇਸ਼ ਨੂੰ ਸਾਫ਼-ਸਾਫ਼ ਲਿਖੋ ਤਾਂ ਜੋ ਅਸੀਂ ਇਸ ਨੂੰ ਪੜ ਸਕੀਏ. ਜੇ ਸੁਨੇਹਾ ਸਪੱਸ਼ਟ ਤੌਰ ਤੇ ਨਹੀਂ ਲਿਖਿਆ ਗਿਆ, ਤਾਂ ਇਹ ਰੱਦ ਕਰ ਦਿੱਤਾ ਜਾਵੇਗਾ.',
  requestDetailInfo2: 'ਕਿਸੇ ਵੀ ਕਿਸਮ ਦੀ ਗੰਦੀ ਜਾਂ ਗਲਤ ਭਾਸ਼ਾ ਦੀ ਵਰਤੋਂ ਕਰਨਾ ਉਸ ਵੀਡੀਓ ਬੇਨਤੀ ਨੂੰ ਠੁਕਰਾ ਦੇਵੇਗਾ ਅਤੇ ਨਤੀਜੇ ਵਜੋਂ ਖਾਤਾ ਬੰਦ ਹੋ ਸਕਦਾ ਹੈ.',
  requestDetailInfo3: 'ਵੀਡੀਓ ਬਣਾਉਣ ਲਈ ਕੀ ਲਿਖਣਾ ਹੈ',
  requestDetailInfo4: 'ਜੇ ਤੁਸੀਂ ਵੀਡੀਓ ਆਪਣੇ ਲਈ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ, ਤਾਂ "ਆਪਣੇ ਲਈ" ਚੁਣੋ',
  requestDetailInfo5: 'ਜੇ ਤੁਸੀਂ ਕਿਸੇ ਹੋਰ ਲਈ ਵੀਡੀਓ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ, "ਕਿਸੇ ਹੋਰ ਲਈ" ਚੁਣੋ.',
  requestDetailInfo6: 'ਜੇ ਤੁਸੀਂ ਜਨਮਦਿਨ ਦੀਆਂ ਵਧਾਈਆਂ ਦੀ ਵੀਡੀਓ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਸੁਨੇਹੇ ਵਿਚ ਲਿਖ ਸਕਦੇ ਹੋ. "ਮੇਰੇ ਦੋਸਤ ਰਮੇਸ਼ ਲਈ ਜਨਮਦਿਨ ਦੀ ਵੀਡੀਓ ਬਣਾਓ, ਜੋ ਅੱਜ 25 ਸਾਲ ਦੇ ਹੋ ਜਾਣਗੇ। ਉਸਨੂੰ ਕਹੋ ਕਿ ਸਾਡੀ ਦੋਸਤੀ ਭੁੱਲ ਨਾ ਜਾਵੇ ਅਤੇ ਮੈਂ ਆਪਣੇ ਪੁਰਾਣੇ ਦਿਨਾਂ ਨੂੰ ਯਾਦ ਕਰ ਰਿਹਾ ਹਾਂ।"',
  createVideoDetail: 'ਵੀਡੀਓ ਬਣਾਓ',
  createVideoDetailInfo: 'ਮੈਂ ਆਪਣੇ ਲਈ ਵੀਡੀਓ ਸੁਨੇਹਾ ਬਣਾਉਣਾ ਚਾਹੁੰਦਾ ਹਾਂ',
  createVideoDetailInfo1: 'ਮੈਂ ਵੀਡੀਓ ਸੁਨੇਹਾ "ਕਿਸੇ ਹੋਰ ਲਈ" ਬਣਾਉਣਾ ਚਾਹੁੰਦਾ ਹਾਂ',
  createVideoDetailInfo2: 'ਵੀਡੀਓ ਨੂੰ ਬਣਾਉਣ ਲਈ ਨਾਮ ਦਿਓ',
  createVideoDetailInfo3: 'ਪੂਰਾ ਨਾਮ ਦਰਜ ਕਰੋ',
  createVideoDetailInfo4: 'ਮੇਰੇ ਕੋਲ {name} ਲਈ ਇੱਕ ਸੁਨੇਹਾ ਹੈ.',
  createVideoDetailInfo5: 'ਆਪਣਾ ਪੂਰਾ ਸੁਨੇਹਾ ਇੱਥੇ ਲਿਖੋ',
  createVideoDetailInfo6: 'ਜੇ ਤੁਸੀਂ ਜਨਮਦਿਨ ਦੀਆਂ ਵਧਾਈਆਂ ਦੀ ਵੀਡੀਓ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਸੁਨੇਹੇ ਵਿਚ ਲਿਖ ਸਕਦੇ ਹੋ. "ਮੇਰੇ ਦੋਸਤ ਰਮੇਸ਼ ਲਈ ਜਨਮਦਿਨ ਦੀ ਵੀਡੀਓ ਬਣਾਓ, ਜੋ ਅੱਜ 25 ਸਾਲ ਦੇ ਹੋ ਜਾਣਗੇ। ਉਸਨੂੰ ਕਹੋ ਕਿ ਸਾਡੀ ਦੋਸਤੀ ਭੁੱਲ ਨਾ ਜਾਵੇ ਅਤੇ ਮੈਂ ਆਪਣੇ ਪੁਰਾਣੇ ਦਿਨਾਂ ਨੂੰ ਯਾਦ ਕਰ ਰਿਹਾ ਹਾਂ।"',
  createVideoDetailInfo7: 'ਆਪਣਾ ਪੂਰਾ ਨਾਮ ਦਿਓ',
  requestVideo: 'ਵੀਡੀਓ ਲਈ ਬੇਨਤੀ ਕਰੋ ਰੁਪਏ {priceTxt}',
  videos: 'ਵੀਡੀਓ',
  home: 'ਘਰ',
  profile: 'ਪ੍ਰੋਫਾਈਲ',
  orders: 'ਆਰਡਰ',
  celebrity: 'ਸੇਲਿਬ੍ਰਿਟੀ',
  celebrities: 'ਮਸ਼ਹੂਰ',
  notifications: 'ਨੋਟੀਫਿਕੇਸ਼ਨ',
  likes: 'ਪਸੰਦ',
  comments: 'ਟਿਪਣੀਆਂ',
  posted: '{post} ਤੇ ਪੋਸਟ ਕੀਤਾ ਗਿਆ',
  validationError: 'ਕ੍ਰਿਪਾ ਕਰਕੇ ਸਾਰੇ ਡੀ',
  validationErrorChar10: 'ਸੰਦੇਸ਼ ਲਈ ਘੱਟੋ ਘੱਟ 10 ਅੱਖਰ ਹੋਣੇ ਜ਼ਰੂਰੀ ਹਨ',
  validationErrorChar3: 'ਪੂਰੇ ਨਾਮ ਲਈ ਘੱਟੋ ਘੱਟ 3 ਅੱਖਰਾਂ ਦੀ ਜ਼ਰੂਰਤ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ',
  commentsError: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਟਿੱਪਣੀ ਲਿਖੋ',
  apiError: 'ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ! ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ',
  readMore: 'ਹੋਰ ਪੜ੍ਹੋ',
  noComments: 'ਕੋਈ ਟਿੱਪਣੀ ਨਹੀਂ',
  noVideo: 'ਕੋਈ ਵੀਡੀਓ ਨਹੀਂ',
  noPhoto: 'ਕੋਈ ਫੋਟੋ ਨਹੀਂ',
  createVideoButton: 'ਵੀਡੀਓ ਬਣਾਓ',
  noOrdersYet: 'ਅਜੇ ਤਕ ਕਿਸੇ ਵੀ ਵੀਡੀਓ ਦਾ ਆਰਡਰ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ ਹੈ',
  noOrders: 'ਕੋਈ ਆਦੇਸ਼ ਨਹੀਂ',
  noNotifications: 'ਕੋਈ ਸੂਚਨਾਵਾਂ ਨਹੀਂ',
  pending: 'ਬਕਾਇਆ',
  approved: 'ਮਨਜ਼ੂਰ',
  incomplete: 'ਅਧੂਰਾ',
  reject: 'ਰੱਦ ਕਰੋ',
  video: 'ਵੀਡੀਓ',
  rejectMessage: 'ਸੁਨੇਹਾ ਅਸਵੀਕਾਰ ਕਰੋ',
  writeComment: 'ਟਿੱਪਣੀ ਲਿਖੋ ...',
  buyNow: 'ਹੁਣੇ ਖਰੀਦੋ',
  seeAll: 'ਸਾਰੇ ਵੇਖੋ',
  congratulation: 'ਵਧਾਈਆਂ',
  congratulationMessage: 'ਤੁਹਾਡਾ ਭੁਗਤਾਨ ਜਮ੍ਹਾਂ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ ਅਤੇ ਵੀਡੀਓ ਬੇਨਤੀ ਭੇਜ ਦਿੱਤੀ ਗਈ ਹੈ',
  userNotFound:'ਯੂਜ਼ਰ ਨਹੀਂ ਲੱਭਿਆ'
}
