export default {
  createdVideoFor: '<b>{celebrity} ಅವರಿಂದ {name} </b> ಗಾಗಿ ವೀಡಿಯೊ ಮಾಡಿ',
  by: 'ಇವರಿಂದ',
  share: 'ಹಂಚಿಕೊಳ್ಳಿ',
  others: 'ಇತರರು',
  and: 'ಮತ್ತು',
  videoFor: '{name} ಗಾಗಿ',
  priceDetails: 'ಬೆಲೆ ವಿವರಗಳು',
  costPrice: 'ಅಸಲಿನ ಬೆಲೆ',
  discount: 'ರಿಯಾಯಿತಿ',
  walletUsed: 'ವಾಲೆಟ್ ಬಳಸಲಾಗಿದೆ',
  totalAmount: 'ಒಟ್ಟು ಮೊತ್ತ',
  download: 'ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
  starOfTheWeek: 'ವಾರದ ನಕ್ಷತ್ರ',
  makeVideo: 'ವೀಡಿಯೊ ಮಾಡಿ',
  aboutMe: 'ನನ್ನ ಬಗ್ಗೆ',
  photo: 'ಫೋಟೋ',
  Instructions: 'ವೀಡಿಯೊವನ್ನು ವಿನಂತಿಸಲು ಸೂಚನೆಗಳು',
  requestDetailInfo1: 'ನಿಮ್ಮ ಸಂದೇಶವನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ಬರೆಯಿರಿ ಇದರಿಂದ ನಾವು ಅದನ್ನು ಓದಬಹುದು. ಸಂದೇಶವನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ಬರೆಯದಿದ್ದರೆ, ಅದನ್ನು ತಿರಸ್ಕರಿಸಲಾಗುತ್ತದೆ.',
  requestDetailInfo2: 'ಯಾವುದೇ ರೀತಿಯ ಕೊಳಕು ಅಥವಾ ತಪ್ಪಾದ ಭಾಷೆಯನ್ನು ಬಳಸುವುದರಿಂದ ಆ ವೀಡಿಯೊ ವಿನಂತಿಯನ್ನು ತಿರಸ್ಕರಿಸುತ್ತದೆ ಮತ್ತು ಇದರ ಪರಿಣಾಮವಾಗಿ ಖಾತೆಯನ್ನು ಮುಚ್ಚಬಹುದು.',
  requestDetailInfo3: 'ವೀಡಿಯೊ ಮಾಡಲು ಏನು ಬರೆಯಬೇಕು',
  requestDetailInfo4: 'ನೀವು ನಿಮಗಾಗಿ ವೀಡಿಯೊವನ್ನು ಮಾಡಲು ಬಯಸಿದರೆ, "ನಿಮಗಾಗಿ" ಆಯ್ಕೆಮಾಡಿ',
  requestDetailInfo5: 'ನೀವು ಬೇರೊಬ್ಬರಿಗಾಗಿ ವೀಡಿಯೊ ಮಾಡಲು ಬಯಸಿದರೆ, "ಬೇರೊಬ್ಬರಿಗಾಗಿ" ಆಯ್ಕೆಮಾಡಿ.',
  requestDetailInfo6: 'ನೀವು ಹುಟ್ಟುಹಬ್ಬದ ಶುಭಾಶಯಗಳ ವೀಡಿಯೊವನ್ನು ಮಾಡಲು ಬಯಸಿದರೆ, ನೀವು ಸಂದೇಶದಲ್ಲಿ ಬರೆಯಬಹುದು. "ಇಂದು 25 ವರ್ಷ ತುಂಬುವ ನನ್ನ ಸ್ನೇಹಿತ ರಮೇಶ್ ಅವರಿಗೆ ಹುಟ್ಟುಹಬ್ಬದ ವಿಡಿಯೋ ಮಾಡಿ. ನಮ್ಮ ಸ್ನೇಹವನ್ನು ಮರೆಯಬಾರದು ಎಂದು ಹೇಳಿ ಮತ್ತು ನಾನು ನಮ್ಮ ಹಳೆಯ ದಿನಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೇನೆ."',
  createVideoDetail: 'ವೀಡಿಯೊ ಮಾಡಿ',
  createVideoDetailInfo: 'ವೀಡಿಯೊ ಸಂದೇಶವನ್ನು "ನನಗಾಗಿ" ಮಾಡಲು ನಾನು ಬಯಸುತ್ತೇನೆ',
  createVideoDetailInfo1: 'ನಾನು "ಬೇರೊಬ್ಬರಿಗಾಗಿ" ವೀಡಿಯೊ ಸಂದೇಶವನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೇನೆ',
  createVideoDetailInfo2: 'ಮಾಡಬೇಕಾದ ವೀಡಿಯೊವನ್ನು ಹೆಸರಿಸಿ',
  createVideoDetailInfo3: 'ಪೂರ್ಣ ಹೆಸರನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ',
  createVideoDetailInfo4: 'ನನಗೆ {name} ಗೆ ಸಂದೇಶವಿದೆ.',
  createVideoDetailInfo5: 'ನಿಮ್ಮ ಪೂರ್ಣ ಸಂದೇಶವನ್ನು ಇಲ್ಲಿ ಬರೆಯಿರಿ',
  createVideoDetailInfo6: 'ನೀವು ಹುಟ್ಟುಹಬ್ಬದ ಶುಭಾಶಯಗಳ ವೀಡಿಯೊವನ್ನು ಮಾಡಲು ಬಯಸಿದರೆ, ನೀವು ಸಂದೇಶದಲ್ಲಿ ಬರೆಯಬಹುದು. "ಇಂದು 25 ವರ್ಷ ತುಂಬುವ ನನ್ನ ಸ್ನೇಹಿತ ರಮೇಶ್ ಅವರಿಗೆ ಹುಟ್ಟುಹಬ್ಬದ ವಿಡಿಯೋ ಮಾಡಿ. ನಮ್ಮ ಸ್ನೇಹವನ್ನು ಮರೆಯಬಾರದು ಎಂದು ಹೇಳಿ ಮತ್ತು ನಾನು ನಮ್ಮ ಹಳೆಯ ದಿನಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೇನೆ."',
  createVideoDetailInfo7: 'ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರನ್ನು ನೀಡಿ',
  requestVideo: 'ವಿನಂತಿಸಿ ವೀಡಿಯೊ ರೂ. {priceTxt}',
  videos: 'ವೀಡಿಯೊಗಳು',
  home: 'ಮನೆ',
  profile: 'ಪ್ರೊಫೈಲ್',
  orders: 'ಆದೇಶಗಳು',
  celebrity: 'ಸೆಲೆಬ್ರಿಟಿ',
  celebrities: 'ಸೆಲೆಬ್ರಿಟಿಗಳು',
  notifications: 'ಅಧಿಸೂಚನೆಗಳು',
  likes: 'ಇಷ್ಟಗಳು',
  comments: 'ಪ್ರತಿಕ್ರಿಯೆಗಳು',
  posted: '{post} ರಂದು ಪೋಸ್ಟ್ ಮಾಡಲಾಗಿದೆ',
  validationError: 'ದಯವಿಟ್ಟು ಎಲ್ಲಾ ಡೆಟಿಲ್ಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ',
  validationErrorChar10: 'ಸಂದೇಶವು ಕನಿಷ್ಠ 10 ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬೇಕು',
  validationErrorChar3: 'ಪೂರ್ಣಹೆಸರು ಕನಿಷ್ಠ 3 ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬೇಕು',
  commentsError: 'ದಯವಿಟ್ಟು ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಬರೆಯಿರಿ',
  apiError: 'ಏನೋ ತಪ್ಪಾಗಿದೆ! ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು',
  readMore: 'ಮತ್ತಷ್ಟು ಓದು',
  noComments: 'ಯಾವುದೇ ಟೀಕೆಗಳಿಲ್ಲ',
  noVideo: 'ವೀಡಿಯೊ ಇಲ್ಲ',
  noPhoto: 'ಫೋಟೋ ಇಲ್ಲ',
  createVideoButton: 'ವೀಡಿಯೊ ರಚಿಸಿ',
  noOrdersYet: 'ಯಾವುದೇ ವೀಡಿಯೊವನ್ನು ಇನ್ನೂ ಆದೇಶಿಸಲಾಗಿಲ್ಲ',
  noOrders: 'ಯಾವುದೇ ಆದೇಶಗಳಿಲ್ಲ',
  noNotifications: 'ಅಧಿಸೂಚನೆಗಳಿಲ್ಲ',
  pending: 'ಬಾಕಿ ಉಳಿದಿದೆ',
  approved: 'ಅನುಮೋದಿಸಲಾಗಿದೆ',
  incomplete: 'ಅಪೂರ್ಣ',
  reject: 'ತಿರಸ್ಕರಿಸಿ',
  video: 'ವೀಡಿಯೊ',
  rejectMessage: 'ಸಂದೇಶವನ್ನು ತಿರಸ್ಕರಿಸಿ',
  writeComment: 'ಅನಿಸಿಕೆಯನ್ನು ಬರೆಯಿರಿ ...',
  buyNow: 'ಈಗ ಖರೀದಿಸು',
  seeAll: 'ನೋಡಿ ಎಲ್ಲಾ',
  congratulation: 'ಅಭಿನಂದನೆಗಳು',
  congratulationMessage: 'ನಿಮ್ಮ ಪಾವತಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ ಮತ್ತು ವೀಡಿಯೊ ವಿನಂತಿಯನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ',
  userNotFound:'ಬಳಕೆದಾರರು ಕಂಡುಬಂದಿಲ್ಲ'
}
