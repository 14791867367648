import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import i18n from '@/utils/i18n'
import store from './store/index'
import './assets/scss/common.scss'
import VueCookie from 'vue-cookies'
import LazyLoadDirective from './components/Js/LazyImageLoading'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
// register globally.
import InfiniteLoading from 'vue-infinite-loading'
Vue.directive('lazyload', LazyLoadDirective)
Sentry.init({
  environment: window.location.hostname == 'localhost' ? 'staging' : null,
  dsn: 'https://7b12841e7d084b1884785b3acf127279@sentry.services-money91.com/13',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })]
})
try {
  Sentry.setUser({ 'id': VueCookie.get('X-Auth-Id') })
} catch (error) {
  console.log(error)
}
Vue.use(InfiniteLoading, { /* options */ })
window.__native_money91 = {}
Vue.config.productionTip = false
Vue.use(VueCookie)

Vue.use(window.ads91)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
