// import fetch from 'cross-fetch'
import { checkStatus, parseJSON } from './responseHandler'
import axios from 'axios'
import { SessionHelper } from './session'
// Make an api call
export default async (baseUrl, url, method = 'post', body) => {
  return axios(`${baseUrl}${url}`, {
    method,
    data: JSON.stringify(body),
    headers: SessionHelper.getHeader()
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      throw error
    })
}
