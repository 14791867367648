<template>
  <div>
    <header class="main-header" v-if="videoTab ==='Notifications'">
      <span class="goback-btn">
        <img @click="back" src="../assets/images/goback-arrow.svg" alt />
      </span>
      <span class="logo">
        <img src="../assets/images/small-logo.png" alt />
      </span>
    </header>

    <div class="video-card" v-for="(list,index) in  VideoListing" :key="index">
      <div class="card-inner">
        <div class="feed-card-head">
          <span class="card-profile">
            <img @click="getProfile(list.celeb_id)" :src="list.request_to_profile_pic" alt />
          </span>
          <div class="card-head-content">
            <p
              v-html="$t('createdVideoFor',{name:ChangeUserName(list.request_from),celebrity:list.request_to})"
            ></p>
            <span>{{$t('posted',{post:ChangeFormat(list.post_time)})}}</span>
          </div>
        </div>
        <div class="feed-video">
          <img
            @click="playVideo(index)"
            src="../assets/images/youtube-play.svg"
            alt
            class="video-icon"
            v-if="playingIndex !== index"
          />
          <!-- <figure v-lazyload class="image__wrapper">
    <ImageSpinner
      class="image__spinner"
    />
    <img
      class="image__item"
      :data-url="list.thumbnail"
      alt="random image"
       v-if="playingIndex !== index" @click="playVideo(index)"
    >
          </figure>-->
          <ImageItem
            class="article-item__image"
            v-if="list.thumbnail && playingIndex !== index"
            :source="list.thumbnail"
          />
          <!-- <img :src="list.thumbnail" alt v-if="playingIndex !== index" @click="playVideo(index)" /> -->
          <VideoPlayer
            :video_link="list.std_res_video"
            :id="list.media_id"
            v-if="playingIndex === index"
          />
        </div>
        <div class="feed-card-footer">
          <div class="user-reply-detail" v-if="list.like_latest_users.length > 0">
            <span class="reply-user">
              <img
                v-for="(likeData,likeIndex) in list.like_latest_users"
                :key="likeIndex"
                :src="getImage(likeData)"
                alt
              />
            </span>
            <span class="user-like-detail">
              <img src="../assets/images/like-fill.svg" alt />
              {{likeName(list.like_latest_users[0].from_user__first_name,list.likes,index)}}
            </span>
            <span class="comment-count">{{list.comments}} {{$t('comments')}}</span>
          </div>
          <div class="reply-block">
            <div
              class="social-icon"
              @click="likeVideo(index,list.likes,list.media_id,list.liked_by_user,list.trans_id)"
            >
              <span class="icon-img">
                <img v-if="!list.liked_by_user" src="../assets/images/like.svg" alt />
                <img v-if="list.liked_by_user" src="../assets/images/like.png" alt />
              </span>
              <span>
                {{$t('likes')}}
                <small>({{list.likes}})</small>
              </span>
            </div>
            <div class="social-icon">
              <span class="icon-img">
                <img src="../assets/images/comment.svg" alt />
              </span>
              <span @click="openComments(list.media_id,list.trans_id,index)">
                {{$t('comments')}}
                <small>({{list.comments}})</small>
              </span>
            </div>
            <div class="social-icon">
              <span class="icon-img">
                <img src="../assets/images/share.svg" alt />
              </span>
              <span @click=" shareCount(list,index)">
                {{$t('share')}}
                <small>({{list.shares}})</small>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Toaster :dataValue="toasterObj" />
      <!-- add card  -->
       <div style="padding: 15px 0 0 0;">
        <ads-91 v-if="index === 1 &&  allTabs === 'home'" :unitIdentifier="productIdentifier" />
       </div>
      <!-- add card end -->

      <!-- celebrity list -->
      <div class="celebrities-slider" v-if="index === 4 &&  allTabs === 'home'">
        <h1>
          {{$t('celebrities')}}
          <span @click="changeStatus('celebrity')">{{$t('seeAll')}}</span>
        </h1>
        <ul>
          <li v-for="(list,index) in celebrityData" :key="index">
            <!-- <img @click="getProfile(list.id)" v-if="list.profile_pic" :src="list.profile_pic" alt /> -->
            <ImageItem
              class="article-item__image"
              v-if="list.profile_pic"
              :source="list.profile_pic"
              :id="list.id"
            />
          </li>
        </ul>
      </div>
      <!-- celebrity list  end-->
    </div>
    <div class="loader" v-if="loading">
      <img src="../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import ImageItem from './Common/ImageItem'
import VideoPlayer from './Common/VideoPlayer'
import ImageSlider from './Common/ImageSlider'
import dummy from '../assets/images/dummy.png'
import Toaster from './Common/Toaster'
import { checkStatus, parseJSON } from '../utils/responseHandler'
import axios from 'axios'
import VueCookie from 'vue-cookies'
import { mapMutations } from 'vuex'
export default {
  name: 'VideoListingCard',
  components: {
    VideoPlayer,
    ImageSlider,
    Toaster,
    ImageItem
  },
  props: {
    dataValue: {
      type: Array
    },
    celebrity: {
      type: Array
    },
    adData: {
      type: Object
    },
    requestData: null
  },
  data () {
    return {
      VideoListing: [],
      playingIndex: null,
      newAdData: {},
      image: '',
      celebrityData: [],
      allTabs: this.$route.name,
      loading: false,
      videoTab: this.$route.name,
      toasterObj: {},
      request_id: null,
      productIdentifier: '0592642b-3e91-4241-b7e5-9b585db2b985'
    }
  },
  watch: {
    dataValue () {
      if (this.dataValue) {
        this.VideoListing = this.dataValue
      }
    },
    celebrity () {
      if (this.celebrity) {
        this.celebrityData = this.celebrity
      }
    },
    adData () {
      if (this.adData) {
        this.newAdData = this.adData
      }
    },
    requestData () {
      if (this.requestData) {
        this.request_id = this.requestData
      }
    }
  },
  created () {},
  mounted () {
    if (this.requestData) {
      this.request_id = this.requestData
      this.getVideo(this.request_id)
    }

    if (this.dataValue) {
      this.VideoListing = this.dataValue
    }
    if (this.celebrity) {
      this.celebrityData = this.celebrity
    }
    if (this.adData) {
      this.newAdData = this.adData
    }
    if (this.requestData) {
      this.request_id = this.requestData
    }
  },
  methods: {
    ...mapMutations(['saveAllVideoList', 'saveAllCelebrityList']),
    mall91 (url) {
      this.saveAllVideoList({
        allVideoListIndex: 0,
        allVideoList: []
      })
      this.saveAllCelebrityList({
        allCelebrityListIndex: 0,
        allCelebrityList: [],
        promotedCelebrity: []
      })
      window.location.href = url
    },
    ChangeUserName (list) {
      if (Number(list)) {
        const regex = /(?<!^).(?!$)/g
        return list.replace(regex, '*')
      } else {
        return list
      }
    },
    ChangeFormat (value) {
      const date = new Date(value)
      return date.toDateString()
    },
    playVideo (index) {
      if (this.playingIndex === index) {
        this.playingIndex = null
      } else {
        this.playingIndex = index
      }
    },
    openComments (value, transId, index) {
      let obj = { id: value, transId: transId, index: index }
      this.$emit('commentsData', obj)
    },
    likeVideo (index, like, mediaId, likeStatus, transId) {
      this.loading = true
      let type = 'likeVideo'
      let count = 1
      let obj = { media_id: mediaId, trans_id: transId }

      if (likeStatus) {
        type = 'unlikeVideo'
        count = -1
        obj = { media_id: mediaId }
      }
      this.$store
        .dispatch({
          type: type,
          payload: obj
        })
        .then(data => {
          this.VideoListing[index].likes = like + count
          this.VideoListing[index].liked_by_user = !likeStatus
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    likeCount (count) {
      if (count === 0) {
        return 0
      } else {
        return count - 1
      }
    },
    getImage (data) {
      let image = data.from_user__profile_pic
      if (image === '') {
        return dummy
      } else {
        return `${image}`
      }
    },
    likeName (value, like, index) {
      let newName = this.ChangeUserName(value)
      if (like === 1) {
        return newName
      } else if (like === 0) {
        this.VideoListing[index].like_latest_users = []
      } else {
        return `${newName} ${this.$t('and')} ${like} ${this.$t('others')}`
      }
    },
    ChangeUserName (list) {
      if (Number(list)) {
        const regex = /(?<!^).(?!$)/g
        return list.replace(regex, '*')
      } else {
        return list
      }
    },
    getProfile (id) {
      this.$router.push({ name: 'celebritiesDetail', params: { id: id } })
    },
    changeStatus (value) {
      this.$emit('status', value)
    },
    getVideo (id) {
      this.loading = true
      this.$store
        .dispatch({
          type: 'getVideo',
          requestId: id
        })
        .then(data => {
          if (data.data.status !== 'no user') {
            if (data.data.length) {
              this.VideoListing.push(...data.data)
            }
            this.loading = false
          } else {
            this.loading = false
            this.toasterObj = {
              status: true,
              msg: 'User not Found',
              color: '#000000'
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: 'Something went wrong! Try again',
            color: '#000000'
          }
        })
    },
    back () {
      this.$emit('closeVideoModel', true)
    },
    isDigit (text) {
      return /^\d+$/.test(Number(text))
    },
    replcePhoneWithAsterik (no) {
      if (this.isDigit(no)) {
        return no.replace(/\d(?=\d{3})/g, '*')
      }
      return no
    },
    shareCount (list, index) {
      this.loading = true
      this.$store
        .dispatch({
          type: 'Share',
          payload: {
            media_id: list.media_id,
            request_id: list.request_id
          }
        })
        .then(data => {
          if (data.data.sucess) {
            this.share(list)
            this.VideoListing[index].shares = list.shares + 1
          }
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: 'Something went wrong! Try again',
            color: '#000000'
          }
          this.loading = false
        })
    },
    async share (list) {
      // const header = {
      //   'Content-Type': 'application/json'
      // }
      // let deepLink = await axios({
      //   method: 'post',
      //   url: `https://api.branch.io/v1/url`,
      //   headers: header,
      //   data: {
      //     branch_key: 'key_live_cfVgkkPBIPJSmXJaiBjdvmcgtxmtPPbj',
      //     channel: 'star91',
      //     feature: 'celebvideo',
      //     campaign: '',
      //     stage: 'prod',
      //     tags: ['star91', 'celebrity'],
      //     data: {
      //       id: 172,
      //       iconImageURL:
      //         'https://d3ojr524tgla9b.cloudfront.net/90c394b7-0687-489a-86e1-6af393e5828d_original.png',
      //       name: 'Star91',
      //       applicationURL: window.location.href,
      //       miniapp: true
      //     }
      //   }
      // })
      //   .then(checkStatus)
      //   .then(parseJSON)
      //   .catch(error => {
      //     console.log('.....>error>.....', error)
      //   })
      let requestName = ''
      if (list.request_for !== '') {
        requestName = list.request_for
      } else {
        requestName = list.request_from
      }
      var obj = {
        imageUrl: list.thumbnail,
        message: `${list.request_to} ने ${this.replcePhoneWithAsterik(
          requestName
        )} के लिए वीडियो मैसेज बनाया है मॉल91 पर \n
          https://money91.app.link
         \nडाउनलोड करें Mall91 ऐप \nMall91 - 30 लाख+ भारतवासियों की विश्वसनीय सुपर ऐप! घर बैठे बैठे ढेरों कमाएं और अपने कमाई का उपयोग करें दैनिक ज़रूरतों का सामान खरीदने में, रीचार्ज/बिल्स भुगतान में या किसी भी दूकान पर पेमेंट करने में! कमाना है बड़ा आसान - बस अपने दोस्तों को रेफेर करके नेटवर्क बनाकर, या मज़ेदार गेम्स टूर्नामेंट्स खेल कर, या सिर्फ हमारे प्रोडक्ट्स व्हाट्सऐप पर शेयर करके! नेटवर्क की हर खरीद पर आप पाएंगे अलग से कमीशन! यही नहीं, हमारी कई और मिनी-ऐप्स उपयोग करें और बनाएं अपने दैनिक जीवन को और भी आसान।`
      }
      if (VueCookie.get('version-code') > 54) {
        this.loading = false
        try {
          JSBridgePlugin.downloadVideoAndShare(
            list.std_res_video,
            `${list.request_to} ने ${this.replcePhoneWithAsterik(
              requestName
            )} के लिए वीडियो मैसेज बनाया है मॉल91 पर  \nडाउनलोड करें Mall91 ऐप \nMall91 - 30 लाख+ भारतवासियों की विश्वसनीय सुपर ऐप! घर बैठे बैठे ढेरों कमाएं और अपने कमाई का उपयोग करें दैनिक ज़रूरतों का सामान खरीदने में, रीचार्ज/बिल्स भुगतान में या किसी भी दूकान पर पेमेंट करने में! कमाना है बड़ा आसान - बस अपने दोस्तों को रेफेर करके नेटवर्क बनाकर, या मज़ेदार गेम्स टूर्नामेंट्स खेल कर, या सिर्फ हमारे प्रोडक्ट्स व्हाट्सऐप पर शेयर करके! नेटवर्क की हर खरीद पर आप पाएंगे अलग से कमीशन! यही नहीं, हमारी कई और मिनी-ऐप्स उपयोग करें और बनाएं अपने दैनिक जीवन को और भी आसान।\n http://money91.app.link/twB3XD1OWQ`
          )
        } catch (err) {
          console.log(err)
        }
      } else {
        this.loading = false
        try {
          JSBridgePlugin.share(JSON.stringify(obj))
        } catch (err) {
          let text = `https://money91.app.link`
          window.open('whatsapp://send?text=' + text)
        }
      }
    }
  }
}
</script>
