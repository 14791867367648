<template>
  <div class="add-img ad_tag">

    <div v-if="details.images" class="swiper-container product-slide">
      <div class="swiper-wrapper">

        <div
          :id="isVideoIndex(index)"
          class="swiper-slide"
          v-for="(productImage,index) in details.images"
          :key="index"
        >
          <span class="image__wrapper" v-if="productImage.original">
            <img class="image__item" :src="productImage.original" alt="random image" />
          </span>
        </div>
      </div>
      <div class="swiper-pagination" v-if="details.images.length > 1"></div>

    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import '../../assets/css/swiper-slider.css'
export default {
  name: 'ImageSlider',
  props: {
    dataValue: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      details: {},
      getImageURL: false,
      videoSrc: '',
      dynamicPagination: false
    }
  },
  methods: {
    isVideoIndex (videoIndex) {
      if (this.details.images.length) {
        videoIndex = videoIndex + 1
      }
    },
    closeVideo () {
      const vid = document.getElementById('itemVideo')
      vid.pause()
      this.$router.go(-1)
    }
  },
  watch: {
    dataValue () {
      this.details = this.dataValue
      if (
        this.details.images &&
        this.details.images.length > 5
      ) {
        this.dynamicPagination = true
      }
      this.$nextTick(() => {
        var swiper = new Swiper('.product-slide', {
          loop: false,
          spaceBetween: 10,
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            dynamicBullets: this.dynamicPagination,
            clickable: true
          }
        })
      })
      if (
        this.details &&
        this.details.images &&
        this.details.images[0] &&
        this.details.images[0].original
      ) {
        this.videoSrc = this.details.images[0].original
        this.getImageURL = true
      }
    }
  },
  mounted () {
    this.details = this.dataValue
    if (
      this.details.images &&
      this.details.images.length > 5
    ) {
      this.dynamicPagination = true
    }
    this.$nextTick(() => {
      var swiper = new Swiper('.product-slide', {
        loop: false,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: this.dynamicPagination,
          clickable: true
        }
      })
    })
    if (
      this.details &&
      this.details.images &&
      this.details.images[0] &&
      this.details.images[0].original
    ) {
      this.getImageURL = true
      this.videoSrc = this.details.images[0].original
    }
  }
}
</script>

<style>
</style>
