import Vue from 'vue'
import VueI18n from 'vue-i18n'
import english from './i18n/english'
import bengali from './i18n/bengali'
import gujarati from './i18n/gujarati'
import hindi from './i18n/hindi'
import kannada from './i18n/kannada'
import malayalam from './i18n/malayalam'
import marathi from './i18n/marathi'
import punjabi from './i18n/punjabi'
import tamil from './i18n/tamil'
import telegu from './i18n/telegu'
Vue.use(VueI18n)

const messages = {
  en: english,
  bn: bengali,
  gu: gujarati,
  hi: hindi,
  kn: kannada,
  ml: malayalam,
  mr: marathi,
  pa: punjabi,
  ta: tamil,
  te: telegu
}

const i18n = new VueI18n({
  locale: 'hi', // set locale
  fallbackLocale: 'hi', // set fallback locale
  messages // set locale messages
})

export default i18n
