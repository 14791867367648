<template>
  <div id="videoplaycnt">
    <div class="popoverlayBg" v-if="popoverlayBg"></div>
    <div class="videoOuter">
      <div class="video-player-box">
        <span class="closeModel" @click="video_CloseModel()">X</span>
        <div class="plyr__video-embed">
          <iframe
            class="my-youtube-player"
            id="youtubePlayer"
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/OrHot4yiq7E?enablejsapi=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowToPlayModel',
  props: {
    video_link: null
  },
  beforeDestroy () {
    document.body.classList.remove('videoBody')
  },
  data () {
    return {
      popoverlayBg: true
    }
  },
  mounted () {},
  computed: {},
  methods: {
    video_CloseModel () {
      this.isVideo = false
      document.body.classList.remove('videoBody')
      $('.my-youtube-player').each(function () {
        this.contentWindow.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*'
        )
      })
    }
  }
}
</script>

<style scoped>
.videoOuter {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 11111;
  background: #000000e6;
  display: none;
}
.videoBody .popoverlayBg {
  display: block;
  z-index: 1111;
}

.videoBody .videoOuter {
  display: block;
}

.video-player-box {
  width: 90%;
  height: auto;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1111;
  padding: 10px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  line-height: 0;
}

#videoplaycnt video {
  max-width: 100vw;
  height: 100vh;
}

.video-player-box .closeModel {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0px;
  z-index: 1;
  border-radius: 5px;
  top: -30px;
  color: #fff;
  line-height: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) and (min-width: 479px) {
  .videoOuter {
    position: fixed;
    width: 100%;
    height: 50%;
    left: 50%;
    top: 50%;
    z-index: 11111;
    background: transparent;
    display: none;
    transform: translate(-50%, -50%);
  }
  .video-player-box {
    width: 50%;
  }
}
</style>
