<template>
  <div id="app">
    <!-- <transition-page> -->
    <!-- <router-view /> -->
    <!-- </transition-page> -->
    <transition :name="transitionName" mode="in-out" @beforeLeave="beforeLeave" @enter="enter">
      <router-view />
    </transition>
  </div>
</template>
<script>
const DEFAULT_TRANSITION = 'slide'
export default {
  name: 'App',
  data () {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION
    }
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION

      next()
    })
  },
  mounted () {
    this.changeLocale()
  },
  methods: {
    beforeLeave (element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter (element) {
      const { height } = getComputedStyle(element)

      element.style.height = this.prevHeight

      setTimeout(() => {
        element.style.height = height
      })
    },
    changeLocale (locale) {
      if (['kn', 'ml'].includes(this.$cookies.get('lang'))) {
        document.querySelector('body').classList.add('activeKannada')
      }
      if (['ta'].includes(this.$cookies.get('lang'))) {
        document.querySelector('body').classList.add('activeTamil')
      }
      this.$i18n.locale = this.$cookies.get('lang') || 'hi'
    }
  }
}
</script>
<style scoped>
#app {
  background: #242a37;
  min-height: 100vh;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
