<template>
  <header class="main-header">
    <span class="goback-btn" v-if=" url === 'home'">
      <img @click="goToHome()" src="../../assets/images/goback-arrow.svg" alt />
    </span>
    <span class="logo">
      <img src="../../assets/images/small-logo.png" alt />
    </span>
    <span class="video-icon">
      <img @click="videoPlay()" src="../../assets/images/videi-icon.svg" alt />
    </span>
    <StarVideoPage ref="vdoRefs" v-if="isVideo" />
  </header>
</template>

<script>
import StarVideoPage from "./StarVideoPage";
export default {
  name: "Header",
  components: {
    StarVideoPage
  },
  props: {},
  data() {
    return {
      isVideo: false,
      url:this.$route.name
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    videoPlay() {
      this.isVideo = true;
      document.body.classList.add("videoBody");
    },
    goToHome() {
      try {
        JSBridgePlugin.gotToHome();
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
