import VueCookie from 'vue-cookies'
// session kyes
export const X_AUTH_TOKEN = 'X-Auth-Token'
export const X_AUTH_ID = 'X-Auth-Id'
export const JWT_TOKEN = 'JWT-TOKEN'
export const APP_NAME = 'app-name'
export const CONTENT_TYPE = 'Content-Type'
export const LANG = 'lang'
export const LATITUDE = 'latitude'
export const LONGITUDE = 'longitude'

// creating session class
export class SessionHelper {
  // save general data in cookie
  static setCookie (_key, _value) {
    VueCookie.set(_key, _value)
  }

  // get general data in cookie
  static getCookie (_key) {
    return VueCookie.get(_key) || null
  }

  // delete cookie
  static clearCookie (_key) {
    SessionHelper.setCookie(_key, null)
  }

  // refeshing jwt token
  static refereshJwtToken () {
    try {
      _JWT.refereshToken = res => {
        if (res != 'fail') {
          SessionHelper.setCookie(JWT_TOKEN, res)
        } else {
          SessionHelper.setCookie(JWT_TOKEN, null)
        }
      }
      JSBridgePlugin.refreshTokens('_JWT.refereshToken')
    } catch (err) {
      SessionHelper.setCookie(JWT_TOKEN, null)
    }
  }

  // fetching header
  static getHeader (contentType = 'application/json') {
    return {
      [CONTENT_TYPE]: contentType,
      [X_AUTH_ID]: SessionHelper.getCookie(X_AUTH_ID),
      [X_AUTH_TOKEN]: SessionHelper.getCookie(X_AUTH_TOKEN),
      [LANG]: SessionHelper.getCookie(LANG) || 'en'
    }
  }
}
