<template>
  <div>
    <div class="video-listings" v-if="videoStatus">
      <Header />
      <ul class="tab-head">
        <li class="cstm_rado" :class="{ active: !isActive }">
          <input
            type="radio"
            id="test1"
            name="radio-group"
            value="videoList"
            v-model="status"
            @change="changeStatus('videoList')"
          />
          <label for="test1">{{ $t("videos") }}</label>
        </li>
        <li class="cstm_rado" :class="{ active: isActive }">
          <input
            type="radio"
            id="test2"
            name="radio-group"
            value="celebrity"
            v-model="status"
            @change="changeStatus('celebrity')"
          />
          <label for="test2">{{ $t("celebrities") }}</label>
        </li>
      </ul>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated bounceInRight"
      >
        <div class="videos-listing-card" v-if="status == 'videoList'">
          <div style="padding: 15px;" v-if="status == 'videoList'">
            <ads-91  :unitIdentifier="BannerIdentifier" />
          </div>

          <VideoListingCard
            :dataValue="videoListingData"
            :celebrity="celebrityData"
            @status="getStatus"
            :adData="adData"
            @commentsData="commentsVideoData"
          />
          <infinite-loading
            v-if="status === 'videoList'"
            spinner="waveDots"
            @infinite="getAllVideo"
          >
            <div slot="no-more">......</div>
            <div class="no-data-status" slot="no-results">
              <span class="no-dt">{{ $t("noVideo") }}</span>
            </div>
          </infinite-loading>
        </div>
      </transition>
      <div style="padding:15px 15px 0 15px;" v-if="status == 'celebrity'">
        <ads-91 :unitIdentifier="LeadIdentifier" />
      </div>

      <transition
        name="custom-classes-transition"
        enter-active-class="animated bounceInRight"
      >
        <CelebrityListing
          v-if="status == 'celebrity'"
          :celebrity="celebrityData"
          :dataValue="promoted"
        />
      </transition>

      <Toaster :dataValue="toasterObj" />
      <Footer />
      <div class="loader" v-if="loading">
        <img src="../assets/images/loader.gif" />
      </div>
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated bounceInLeft"
    >
      <VideoComments
        v-if="!videoStatus"
        :popupData="commentsObjData"
        @closeCommentsPopup="changeVideoStatus"
      />
    </transition>
  </div>
</template>

<script>
import '../assets/scss/video-listings.scss'
import VideoListingCard from '@/components/VideoListingCard'
import Header from '@/components/Common/Header'
import CelebrityListing from '@/components/CelebrityListing'
import Footer from '@/components/Common/Footer'
import VideoComments from '@/components/VideoComments'
import { mapGetters, mapMutations } from 'vuex'
import Toaster from '@/components/Common/Toaster'
export default {
  name: 'Home',
  components: {
    VideoListingCard,
    CelebrityListing,
    Header,
    Footer,
    Toaster,
    VideoComments
  },
  data () {
    return {
      videoListingData: [],
      celebrityData: [],
      status: 'videoList',
      isActive: false,
      VideoListIndex: 0,
      CelebrityListIndex: 0,
      promoted: {},
      loading: false,
      adData: {},
      toasterObj: {},
      videoStatus: true,
      commentsObjData: {},
      LeadIdentifier: '82db509b-702b-4173-b626-b0756e60e688',
      BannerIdentifier: '0ff24bda-e6f0-42ef-8d83-ba1e4de50af4'
    }
  },
  computed: {
    ...mapGetters([
      'allVideoListIndex',
      'allCelebrityListIndex',
      'allVideoList',
      'allCelebrityList',
      'promotedCelebrity'
    ])
  },
  mounted () {
    if (this.$cookies.get('status')) {
      if (this.$cookies.get('status') === 'celebrity') {
        this.status = this.$cookies.get('status')
        this.isActive = !this.isActive
      }
    }
    if (this.$route.name === 'home') {
      this.getAd()
    }
    //  for allVideoList
    if (this.allVideoList.length === 0) {
      // this.loading = true;
      if (this.status === 'celebrity') {
        this.getAllVideo()
        this.getCelebrity()
      }
    } else {
      // this.loading = true
      this.videoListingData = this.allVideoList
      this.VideoListIndex = this.allVideoListIndex
      this.loading = false
    }
    // for allCelebrityList
    if (this.allCelebrityList.length === 0) {
      if (this.status !== 'celebrity') {
        this.getCelebrity()
      }
    } else {
      // this.loading = true
      this.promoted = this.promotedCelebrity
      this.celebrityData = this.allCelebrityList
      this.CelebrityListIndex = this.allCelebrityListIndex
      this.loading = false
    }
  },
  methods: {
    ...mapMutations([
      'saveAllVideoList',
      'saveAllCelebrityList',
      'saveUserProfileData',
      'savePromotedCeleb'
    ]),
    commentsVideoData (obj) {
      this.videoStatus = false
      this.commentsObjData = obj
    },
    changeVideoStatus (value) {
      this.videoListingData[value.index].comments = value.count
      this.videoStatus = true
    },
    getStatus (value) {
      this.status = value
      this.isActive = !this.isActive
    },
    changeStatus (status) {
      this.$cookies.set('status', status)
      this.status = status
      if (status === 'videoList') {
        this.isActive = !this.isActive
      } else {
        this.isActive = !this.isActive
      }
    },
    getAllVideo ($state) {
      // if (this.VideoListIndex === 0) {
      //   this.loading = true
      // } else {
      //   this.loading = false
      // }
      this.$store
        .dispatch({
          type: 'getVideoList',
          startIndex: this.VideoListIndex,
          payload: 1
        })
        .then(data => {
          if (data.data.status !== 'no user') {
            if (data.data.length) {
              this.videoListingData.push(...data.data)
              this.VideoListIndex = this.VideoListIndex + 10
              this.saveAllVideoList({
                allVideoListIndex: this.VideoListIndex,
                allVideoList: this.videoListingData
              })
              $state && $state.loaded()
            } else {
              $state && $state.complete()
            }
            this.loading = false
          } else {
            this.loading = false
            this.toasterObj = {
              status: true,
              msg: this.$t('userNotFound'),
              color: '#000000'
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    },
    getCelebrity ($state) {
      // this.loading = true;
      this.$store
        .dispatch({
          type: 'getCelebrityList',
          startIndex: this.CelebrityListIndex
        })
        .then(data => {
          if (data.data.status !== 'no user') {
            this.saveUserProfileData({ userProfileData: data.data.user })
            this.promoted = data.data.promoted
            this.savePromotedCeleb({ promoted: data.data.promoted })
            if (data.data.celebrities.length) {
              this.celebrityData.push(...data.data.celebrities)
              this.CelebrityListIndex = this.CelebrityListIndex + 20
              this.saveAllCelebrityList({
                allCelebrityListIndex: this.CelebrityListIndex,
                allCelebrityList: this.celebrityData,
                promotedCelebrity: this.promoted
              })
              // $state && $state.loaded();
            } else {
              // $state && $state.complete();
            }
            // this.loading = false;
          } else {
            // this.toasterObj = {
            //   status: true,
            //   msg: this.$t('userNotFound'),
            //   color: "#000000"
            // };
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
          // this.loading = false;
        })
    },
    getAd () {
      this.$store
        .dispatch({
          type: 'getProduct'
        })
        .then(data => {
          this.adData = data.data
        })
        .catch(err => {
          console.log(err)
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    }
  }
}
</script>
