export default {
  createdVideoFor: 'Create a video for <b>{name} by {celebrity}</b>',
  by: 'by',
  share: 'Share',
  others: 'others',
  and: 'and',
  videoFor: 'For {name}',
  priceDetails: 'Price Details',
  costPrice: 'Cost Price',
  discount: 'Discount',
  walletUsed: 'Wallet Used',
  totalAmount: 'Total Amount',
  download: 'Download',
  starOfTheWeek: 'Star of the week',
  makeVideo: 'Make a Video',
  aboutMe: 'About Me',
  photo: 'Photo',
  Instructions: 'Instructions for requesting video',
  requestDetailInfo1: 'Write your message clearly so that we can read it. If the message is not clearly written, it will be rejected.',
  requestDetailInfo2: 'Using any form of dirty or incorrect language will reject that video request and the account may be closed as a result.',
  requestDetailInfo3: 'What to write to make a video',
  requestDetailInfo4: 'If you want to make the video for yourself, select "for yourself"',
  requestDetailInfo5: 'If you want to make the video for someone else, select "For someone else".',
  requestDetailInfo6: 'If you want to make a video of the birthday greetings, then you can write in the message. "Make a birthday video for my friend Ramesh, who will be 25 years old today. Tell him not to forget our friendship and I miss our old days."',
  createVideoDetail: 'Make video',
  createVideoDetailInfo: 'I want to make the video message "for myself"',
  createVideoDetailInfo1: 'I want to make the video message "for someone else"',
  createVideoDetailInfo2: 'Name the video to be made for',
  createVideoDetailInfo3: 'Enter full name here',
  createVideoDetailInfo4: 'I have a message for {name}.',
  createVideoDetailInfo5: 'Write your full message here',
  createVideoDetailInfo6: 'If you want to make a video of the birthday greetings, then you can write in the message. "Make a birthday video for my friend Ramesh, who will be 25 years old today. Tell him not to forget our friendship and I miss our old days."',
  createVideoDetailInfo7: 'Give your full name',
  requestVideo: 'Request video Rs.{priceTxt}',
  videos: 'Videos',
  home: 'Home',
  profile: 'Profile',
  orders: 'Orders',
  celebrity: 'Celebrity',
  celebrities: 'Celebrities',
  notifications: 'Notifications',
  likes: 'Like',
  comments: 'Comment',
  posted: 'Posted on : {post}',
  validationError: 'Please fill all the deatils',
  validationErrorChar10: 'Message must have minimum of 10 characters required',
  validationErrorChar3: 'Fullname must have Minimum of 3 characters required',
  commentsError: 'Please write a comment',
  apiError: 'Something went wrong! Try again',
  readMore: 'Read more',
  noComments: 'No Comments',
  noVideo: 'No Video',
  noPhoto: 'No Photo',
  createVideoButton: 'Create Video',
  noOrdersYet: 'No video has been ordered yet',
  noOrders: 'No orders',
  noNotifications: 'No Notifications',
  pending: 'Pending',
  approved: 'Approved',
  incomplete: 'Incomplete',
  reject: 'Reject',
  video: 'Video',
  rejectMessage: 'Reject Message',
  writeComment: 'Write a comment ...',
  buyNow: 'Buy Now',
  seeAll: 'See All',
  congratulation: 'Congratulation',
  congratulationMessage: 'Your payment has been complete and the video request has been sent',
  userNotFound:'User not Found' 
}
