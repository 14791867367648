/**
 * List of abusive words
 */
export default [
  'रेफेरल', 'रेफर', 'Refferal', 'रेफरल', 'refferal', 'Refer',
  'aand mat kaha',
  'baap ke lavde',
  'chodu',
  'chodoo',
  'choodu',
  'gandu',
  'gaandu',
  'gandoo',
  'bhosad',
  'bhosada',
  'bhosadaa',
  'bhosadaaa',
  'bhosadii',
  'bhosadika',
  'bhosadike',
  'bhosadiki',
  'bosadike',
  'bakrichod',
  'balatkaar',
  'behen ke laude',
  'betichod',
  'bhai chhod',
  'bhayee chod',
  'bhen chhod',
  'bhaynchod',
  'behanchod',
  'behenchod',
  'bhen ke lode',
  'bhosdi',
  'bhosdike',
  'chipkai ki choot ke paseene',
  'cha cha chod',
  'chod ke bal ka kida',
  'chopre he randi',
  'chudasi',
  'chut ka maindak',
  'chutia',
  'chutiya',
  'chootia',
  'chutiye',
  'dheeli choot',
  'choot',
  'chootiya',
  'gaand chaat mera',
  'gaand',
  'gaand ka khadda',
  'gaand ke dhakan',
  'gaand mein kida',
  'gandi chut mein sadta hua ganda kida',
  'gandmasti',
  'jhaat ke baal',
  'jhat lahergaya',
  'jhatoo',
  'jhantu',
  'kukarchod',
  'kutha sala',
  'kuthta buraanahe kandaa nahi pattaahe',
  'lode jesi shakal ke',
  'lode ke baal',
  'lund khajoor',
  'lund chuse',
  'lund',
  'luhnd',
  'lundh',
  'madar chod',
  'maadarchod',
  'maadar',
  'madar',
  'chod',
  'madarchod',
  'madarchod ke aulaad',
  'mader chod',
  'mai chod',
  'mera mume le',
  'mere fudi kha ley',
  'meri ghand ka baal',
  'randi',
  'randi ka choda',
  'suzit',
  'sust lund ki padaish',
  'tatti ander lele',
  'tere baap ki gaand',
  'teri chute mai chuglee',
  'teri gaand mein haathi ka lund',
  'teri maa ki choot',
  'teri maa ki sukhi bhos',
  'teri phuphi ki choot mein',
  'teri bhosri mein aag',
  'teri gaand me danda',
  'teri ma ki choot me bara sa land',
  'teri ma ki chudaye bandar se hui',
  'teri ma randi',
  'teri maa ke bable',
  'teri maa ke bhosade ke baal',
  'tu tera maa ka lauda',
  'amma ki chut',
  'bhaand me jaao',
  'bhadva',
  'bhadve',
  'chodika',
  'bhadwe ki nasal',
  'bhen ke lode maa chuda',
  'bhosad chod',
  'bhosadchod',
  'bhosadi ke',
  'bhosdee kay',
  'bhosdi k',
  'bulle ke baal',
  'bursungha',
  'camina',
  'chod bhangra',
  'choot k bhoot',
  'choot k pakode',
  'choot ka paani',
  'choot ka pissu',
  'choot ki jhilli',
  'chudpagal',
  'chut ke makkhan',
  'chootiye',
  'gaand maar bhen chod',
  'gand mein louda',
  'gandi fuddi ki gandi auladd',
  'haram zaadaa',
  'harami',
  'jhaat',
  'kaala lund',
  'kaali kutti',
  'kuthri',
  'kutte',
  'kutte ki olad',
  'lavde ka baal',
  'lavde',
  'lodu',
  'lowde ka bal',
  'lund k laddu',
  'lund mera muh tera',
  'maa-cho',
  'maal chhodna',
  'mahder chod',
  'mera gota moo may lay',
  'mome ka pasina chat',
  'moo may lay mera',
  'padosi ki aulaad',
  'rand ki moot',
  'randi baj',
  'randi ka larka',
  'randi ke bacche',
  'randi ke beej',
  'saale lm',
  'sab ka lund teri ma ki chut mein',
  'sinak se paida hua',
  'suvar chod',
  'suwar ki aulad',
  'tera gittha',
  'tere maa ka bur',
  'teri behen ka bhosda faadu',
  'teri gand mai ghadhe ka lund',
  'teri ma bahar chud rahi he',
  'teri ma ko kutta chode',
  'teri maa ka bhosra',
  'teri maa ka boba chusu',
  'teri maa ki choot me kutte ka lavda',
  'teri maa ki chut',
  'teri maa ki chute',
  'tuzya aaichi kanda puchi',
  'bhadavya',
  'bhikaar',
  'bulli',
  'chinaal',
  'chut',
  'gand',
  'maadarbhagat',
  'chodubhagat',
  'lundfakir',
  'gandit',
  'jhavadya',
  'laudu',
  'lavadya',
  'muttha',
  'raandichya',
  'madarchoth',
  'लौड़ा',
  'चढ़ेगी',
  'लेगी',
  'चुदाई',
  'चूची',
  'chuchi',
  'चुची',
  'chuchee',
  'chod',
  'choot',
  'penis',
  'पेनिस',
  'lund',
  'लण्ड',
  'choos',
  'चूस',
  'degi',
  'देगी',
  'laude',
  'लौड़े',
  'tatte',
  'टट्टे',
  'behenchod',
  'बहनचोद',
  'Bhosadike',
  'भोसड़ीके',
  'sex',
  'सेक्स',
  'chudegi',
  'चूत',
  'chudai',
  'चुदाई',
  'chut',
  'छूट',
  'pussy',
  'चूत',
  'पुसी',
  'chutiya',
  'chutia',
  'चूतिया',
  'chudan',
  'चुदान',
  'gandu',
  'गांडू',
  'gand',
  'गांड',
  'gaand',
  'jhaat',
  'झाट',
  'chutad',
  'bob',
  'vegene',
  'legi',
  'ghand',
  'maa',
  'behen',
  'beti',
  'चूतड़',
  'chudie',
  'चुदाई',
  'boobs',
  'बूब्स',
  'tits',
  'टिट्स',
  'nipple',
  'निप्पल',
  'buble',
  'बब्ले',
  'दबादे',
  'भोसड़ा',
  'रंडी',
  'randi',
  'randee',
  'कुतिया',
  'kutiya',
  'छेद',
  'साली',
  'salee',
  'कुत्ती',
  'kuttee',
  'kuttii',
  'रांड',
  'चूतिया',
  'छिनाल',
  'छेद',
  'रंडीपन',
  'बेटीचोद',
  'मादरचोद',
  'चुद',
  'मुँह',
  'boob',
  'fuck',
  'fuck',
  'show',
  'lick',
  'motherfucker',
  'bhosdike',
  'matherchod',
  'maderchod',
  'rand',
  'kutti',
  'raand',
  'bhosdiwali',
  'Chuchu', 'bable', 'bhosdu', 'gandu', 'mamme', 'chuchi', 'chuche', 'chuch', 'chooch',
  '4r5e',
  '5h1t',
  '5hit',
  'a55',
  'anal',
  'ar5e',
  'arrse',
  'arse',
  'ass',
  'ass-fucker',
  'asses',
  'assfucker',
  'assfukka',
  'asshole',
  'assholes',
  'asswhole',
  'a_s_s',
  'b!tch',
  'b00bs',
  'b00b5',
  'b17ch',
  'b1tch',
  'ballbag',
  'balls',
  'ballsack',
  'bastard',
  'beastial',
  'beastiality',
  'bellend',
  'bestial',
  'bestiality',
  'bi+ch',
  'biatch',
  'bitch',
  'bitcher',
  'bitchers',
  'bitches',
  'bitchin',
  'bitching',
  'bloody',
  'blow job',
  'blowjob',
  'blowjobs',
  'boiolas',
  'bollock',
  'bollok',
  'boner',
  'boob',
  'boobs',
  'booobs',
  'boooobs',
  'booooobs',
  'booooooobs',
  'breasts',
  'buceta',
  'bugger',
  'bum',
  'bunny fucker',
  'butt',
  'butthole',
  'buttmuch',
  'buttplug',
  'c0ck',
  'c0cksucker',
  'carpet muncher',
  'cawk',
  'chink',
  'cipa',
  'cl1t',
  'clit',
  'clitoris',
  'clits',
  'cnut',
  'cock',
  'cock-sucker',
  'cockface',
  'cockhead',
  'cockmunch',
  'cockmuncher',
  'cocks',
  'cocksuck',
  'cocksucked',
  'cocksucker',
  'cocksucking',
  'cocksucks',
  'cocksuka',
  'cocksukka',
  'cok',
  'cokmuncher',
  'coksucka',
  'coon',
  'cox',
  'crap',
  'cum',
  'cummer',
  'cumming',
  'cums',
  'cumshot',
  'cunilingus',
  'cunillingus',
  'cunnilingus',
  'cunt',
  'cuntlick',
  'cuntlicker',
  'cuntlicking',
  'cunts',
  'cyalis',
  'cyberfuc',
  'cyberfuck',
  'cyberfucked',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking',
  'd1ck',
  'dick',
  'dickhead',
  'dildo',
  'dildos',
  'dink',
  'dinks',
  'dirsa',
  'dlck',
  'dog-fucker',
  'doggin',
  'dogging',
  'donkeyribber',
  'doosh',
  'duche',
  'dyke',
  'ejaculate',
  'ejaculated',
  'ejaculates',
  'ejaculating',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'f u c k',
  'f u c k e r',
  'f4nny',
  'fag',
  'fagging',
  'faggitt',
  'faggot',
  'faggs',
  'fagot',
  'fagots',
  'fags',
  'fanny',
  'fannyflaps',
  'fannyfucker',
  'fanyy',
  'fatass',
  'fcuk',
  'fcuker',
  'fcuking',
  'feck',
  'fecker',
  'felching',
  'fellate',
  'fellatio',
  'fingerfuck',
  'fingerfucked',
  'fingerfucker',
  'fingerfuckers',
  'fingerfucking',
  'fingerfucks',
  'fistfuck',
  'fistfucked',
  'fistfucker',
  'fistfuckers',
  'fistfucking',
  'fistfuckings',
  'fistfucks',
  'flange',
  'fook',
  'fooker',
  'fuck',
  'fucka',
  'fucked',
  'fucker',
  'fuckers',
  'fuckhead',
  'fuckheads',
  'fuckin',
  'fucking',
  'fuckings',
  'fuckingshitmotherfucker',
  'fuckme',
  'fucks',
  'fuckwhit',
  'fuckwit',
  'fudge packer',
  'fudgepacker',
  'fuk',
  'fuker',
  'fukker',
  'fukkin',
  'fuks',
  'fukwhit',
  'fukwit',
  'fux',
  'fux0r',
  'f_u_c_k',
  'gangbang',
  'gangbanged',
  'gangbangs',
  'gaysex',
  'goatse',
  'hardcoresex',
  'heshe',
  'hoar',
  'hoare',
  'hoer',
  'homo',
  'hore',
  'horniest',
  'hotsex',
  'jack-off',
  'jackoff',
  'jap',
  'jerk-off',
  'jiz',
  'jizm',
  'jizz',
  'kawk',
  'knobead',
  'knobed',
  'knobend',
  'knobhead',
  'knobjocky',
  'knobjokey',
  'kock',
  'kondum',
  'kondums',
  'kum',
  'kummer',
  'kumming',
  'kums',
  'kunilingus',
  'l3i+ch',
  'l3itch',
  'labia',
  'lust',
  'lusting',
  'm0f0',
  'm0fo',
  'm45terbate',
  'ma5terb8',
  'ma5terbate',
  'masochist',
  'master-bate',
  'masterb8',
  'masterbat*',
  'masterbat3',
  'masterbate',
  'masterbation',
  'masterbations',
  'masturbate',
  'mo-fo',
  'mof0',
  'mofo',
  'mothafuck',
  'mothafucka',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking',
  'mothafuckings',
  'mothafucks',
  'mother fucker',
  'motherfuck',
  'motherfucked',
  'motherfucker',
  'motherfuckers',
  'motherfuckin',
  'motherfucking',
  'motherfuckings',
  'motherfuckka',
  'motherfucks',
  'muff',
  'muthafecker',
  'muthafuckker',
  'muther',
  'mu therfucker',
  'n1gga',
  'n1gger',
  'nazi',
  'nigg3r',
  'nigg4h',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'niggers',
  'nob jokey',
  'nobhead',
  'nobjocky',
  'nobjokey',
  'numbnuts',
  'nutsack',
  'pecker',
  'penisfucker',
  'phonesex',
  'phuck',
  'phuk',
  'phuked',
  'phuking',
  'phukked',
  'phukking',
  'phuks',
  'phuq',
  'pigfucker',
  'pimpis',
  'piss',
  'pissed',
  'pisser',
  'pissers',
  'pisses',
  'pissflaps',
  'pissin',
  'pissing',
  'pissoff',
  'pornography',
  'pornos',
  'prick',
  'pricks',
  'pron',
  'pube',
  'pusse',
  'pussi',
  'pussies',
  'pussy',
  'pussys',
  'rimjaw',
  'rimming',
  's hit',
  's.o.b.',
  'sadist',
  'schlong',
  'screwing',
  'scroat',
  'scrote',
  'scrotum',
  'semen',
  'sex',
  'sh!+',
  'sh!t',
  'sh1t',
  'shag',
  'shagger',
  'shaggin',
  'shagging',
  'shi+',
  'shit',
  'shitdick',
  'shite',
  'shited',
  'shitey',
  'shitfuck',
  'shitfull',
  'shithead',
  'shiting',
  'shitings',
  'shits',
  'shitted',
  'shitter',
  'shitters',
  'shitting',
  'shittings',
  'shitty',
  'skank',
  'slut',
  'sluts',
  'smegma',
  'smut',
  'snatch',
  'son-of-a-bitch',
  'spac',
  'spunk',
  's_h_i_t',
  't1tt1e5',
  't1tties',
  'teets',
  'teez',
  'titfuck',
  'tittie5',
  'tittiefucker',
  'tittyfuck',
  'tittywank',
  'titwank',
  'tosser',
  'turd',
  'twathead',
  'twatty',
  'twunt',
  'twunter',
  'w00se',
  'wang',
  'wank',
  'wanker',
  'wanky',
  'whoar',
  'whore',
  'willies',
  'willy',
  'xrated'
]
