export default {
  createdVideoFor: '<b>{name} દ્વારા {celebrity}</b> દ્વારા વિડિઓ બનાવો',
  by: 'દ્વારા',
  share: 'શેર કરો',
  others: 'અન્ય',
  and: 'અને',
  videoFor: '{name} માટે',
  priceDetails: 'ભાવ વિગતો',
  costPrice: 'પડતી કિંમત',
  discount: 'ડિસ્કાઉન્ટ',
  walletUsed: 'વletલેટ વપરાયેલ',
  totalAmount: 'કુલ રકમ',
  download: 'ડાઉનલોડ કરો',
  starOfTheWeek: 'સપ્તાહનો તારો',
  makeVideo: 'વિડિઓ બનાવો',
  aboutMe: 'મારા વિશે',
  photo: 'ફોટો',
  Instructions: 'વિનંતી વિડિઓ માટે સૂચનો',
  requestDetailInfo1: 'તમારો સંદેશ સ્પષ્ટ લખો જેથી અમે તેને વાંચી શકીએ. જો સંદેશ સ્પષ્ટ રીતે લખ્યો ન હોય, તો તે નકારવામાં આવશે.',
  requestDetailInfo2: 'કોઈપણ પ્રકારની ગંદા અથવા ખોટી ભાષાના ઉપયોગથી તે વિડિઓ વિનંતીને નકારવામાં આવશે અને પરિણામે એકાઉન્ટ બંધ થઈ શકે છે.',
  requestDetailInfo3: 'વિડિઓ બનાવવા માટે શું લખવું',
  requestDetailInfo4: 'જો તમે વિડિઓ તમારા માટે બનાવવા માંગતા હો, તો "તમારા માટે" પસંદ કરો',
  requestDetailInfo5: 'જો તમે કોઈ બીજા માટે વિડિઓ બનાવવા માંગતા હો, તો "બીજા કોઈ માટે" પસંદ કરો.',
  requestDetailInfo6: 'જો તમે જન્મદિવસની શુભેચ્છાઓનો વિડિઓ બનાવવા માંગતા હો, તો તમે સંદેશમાં લખી શકો છો. "મારા મિત્ર રમેશ માટે જન્મદિવસનો વિડિઓ બનાવો, જે આજે 25 વર્ષનો હશે. તેને કહો કે અમારી મિત્રતા ભૂલશો નહીં અને હું અમારા જૂના દિવસોને ચૂકી રહ્યો છું."',
  createVideoDetail: 'વિડિઓ બનાવો',
  createVideoDetailInfo: 'હું વિડિઓ સંદેશ "મારા માટે" બનાવવા માંગું છું',
  createVideoDetailInfo1: 'હું વિડિઓ સંદેશ "કોઈ બીજા માટે" બનાવવા માંગું છું',
  createVideoDetailInfo2: 'બનાવનારી વિડિઓનું નામ',
  createVideoDetailInfo3: 'અહીં પૂરું નામ દાખલ કરો',
  createVideoDetailInfo4: 'મારી પાસે {name} માટે એક સંદેશ છે.',
  createVideoDetailInfo5: 'તમારો સંપૂર્ણ સંદેશ અહીં લખો',
  createVideoDetailInfo6: 'જો તમે જન્મદિવસની શુભેચ્છાઓનો વિડિઓ બનાવવા માંગતા હો, તો તમે સંદેશમાં લખી શકો છો. "મારા મિત્ર રમેશ માટે જન્મદિવસનો વિડિઓ બનાવો, જે આજે 25 વર્ષનો હશે. તેને કહો કે અમારી મિત્રતા ભૂલશો નહીં અને હું અમારા જૂના દિવસોને ચૂકી રહ્યો છું."',
  createVideoDetailInfo7: 'તમારું પૂરું નામ આપો',
  requestVideo: 'વિનંતી વિડિઓ રૂ. {priceTxt}',
  videos: 'વિડિઓઝ',
  home: 'ખેર',
  profile: 'પ્રોફાઇલ',
  orders: 'ઓર્ડર',
  celebrity: 'સેલિબ્રિટી',
  celebrities: 'હસ્તીઓ',
  notifications: 'સૂચનાઓ',
  likes: 'પસંદ છે',
  comments: 'ટિપ્પણીઓ',
  posted: '{post} પર પોસ્ટ કરેલ',
  validationError: 'કૃપા કરીને બધા ડિએટીલ્સ ભરો',
  validationErrorChar10: 'સંદેશમાં ઓછામાં ઓછા 10 અક્ષરો હોવા આવશ્યક છે',
  validationErrorChar3: 'પૂર્ણ નામમાં ઓછામાં ઓછા 3 અક્ષરો હોવા આવશ્યક છે',
  commentsError: 'એક ટિપ્પણી લખો',
  apiError: 'કંઈક ખોટું થયું! ફરીથી પ્રયત્ન કરો',
  readMore: 'વધુ વાંચો',
  noComments: 'કોઈ ટીપ્પણીઓ નહિ',
  noVideo: 'કોઈ વિડિઓ નથી',
  noPhoto: 'કોઈ ફોટો નથી',
  createVideoButton: 'વિડિઓ બનાવો',
  noOrdersYet: 'હજી સુધી કોઈ વિડિઓ ર્ડર કરવામાં આવી નથી',
  noOrders: 'કોઈ ઓર્ડર નથી',
  noNotifications: 'કોઈ સૂચનાઓ નથી',
  pending: 'બાકી',
  approved: 'મંજુર',
  incomplete: 'અધૂરું',
  reject: 'અસ્વીકાર',
  video: 'વિડિઓ',
  rejectMessage: 'સંદેશ નકારો',
  writeComment: 'એક ટિપ્પણી લખો ...',
  buyNow: 'હમણાં જ ખરીદો',
  seeAll: 'બધા જુઓ',
  congratulation: 'અભિનંદન',
  congratulationMessage: 'તમારી ચુકવણી સબમિટ કરવામાં આવી છે અને વિડિઓ વિનંતી મોકલવામાં આવી છે',
  userNotFound:'ઉપયોગકર્તા ના મળ્યો'
}
