<template>
  <div class="videos-listing-card">
    <ul>
      <li v-for="(list,index) in celebrityList" :key="index">
        <div class="celebrity-img">
          <!-- <img @click="goToProfile(list.id)" :src="list.profile_pic" alt /> -->
          <ImageItem
            class="article-item__image"
            v-if="list.profile_pic"
            :source="list.profile_pic"
            :id="list.id"
          />
        </div>
        <div class="celebrity-card-footer">
          <div class="content">
            <h2>
              <span>{{list.name}}</span>
              <span>{{list.profession}}</span>
            </h2>
            <span class="current-price">Rs.{{list.fee}}</span>
            <strike>Rs.500</strike>
          </div>
          <span class="create-video-btn" @click="requestedVideo(list.id)">{{$t('makeVideo')}}</span>
        </div>
      </li>
    </ul>
    <Toaster :dataValue="toasterObj" />
    <infinite-loading spinner="waveDots" @infinite="getCelebrity">
      <div slot="no-more">......</div>
      <div class="no-data-status" slot="no-results">
        <span class="no-dt" v-if="celebrityList.length > 0"></span>
      </div>
    </infinite-loading>
    <div class="loader" v-if="loading">
      <img src="../../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import ImageItem from "./ImageItem";
import { mapMutations, mapGetters } from "vuex";
import Toaster from "./Toaster";
export default {
  name: "CelebrityCard",
  components: {
    Toaster,
    ImageItem
  },
  props: {
    dataValue: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      celebrityList: [],
      CelebrityListIndex: 0,
      status: this.$cookies.get("status"),
      loading: false,
      toasterObj: {}
    };
  },
  computed: {
    ...mapGetters(["allCelebrityList", "allCelebrityListIndex"])
  },
  mounted() {
    if (this.allCelebrityList) {
      this.celebrityList = this.allCelebrityList;
      this.CelebrityListIndex = this.allCelebrityListIndex;
    }
  },
  methods: {
    ...mapMutations(["saveAllCelebrityList", "savePromotedCeleb"]),
    goToProfile(id) {
      this.$router.push({ name: "celebritiesDetail", params: { id: id } });
    },
    getCelebrity($state) {
      this.$store
        .dispatch({
          type: "getCelebrityList",
          startIndex: this.CelebrityListIndex
        })
        .then(data => {
          if (data.data.status !== "no user") {
            if (data.data.celebrities.length > 0) {
              this.celebrityList.push(...data.data.celebrities);
              this.CelebrityListIndex = this.CelebrityListIndex + 20;
              this.saveAllCelebrityList({
                allCelebrityListIndex: this.CelebrityListIndex,
                allCelebrityList: this.celebrityList
              });
              this.savePromotedCeleb({ promoted: data.data.promoted });
              $state && $state.loaded();
            } else {
              $state && $state.complete();
            }
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    requestedVideo(value) {
      this.$router.push({ name: "celebritiesDetail", params: { id: value } });
    }
  }
};
</script>
