<template>
  <figure @click="goToProfile(id)" v-lazyload class="image__wrapper">
    <ImageSpinner class="image__spinner" />
    <img class="image__item" :data-url="source" alt="random image" />
  </figure>
</template>

<script>
import ImageSpinner from "./ImageSpinner";

export default {
  name: "ImageItem",
  components: {
    ImageSpinner
  },
  props: {
    source: {
      type: String,
      required: true
    },
    id: null
  },
  methods: {
    goToProfile(id) {
      if (id) {
        this.$router.push({ name: "celebritiesDetail", params: { id: id } });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.celebrity-img{
    .image {
  &__wrapper {
      height: 48vh;
  }
}
}
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    height: 35vh;
    overflow: hidden;
    position: relative;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }

        &__spinner {
          display: none;
          width: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
