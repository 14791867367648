export default {
  createdVideoFor: '<b>{name}দ্বারা {celebrity}</b> দ্বারা একটি ভিডিও তৈরি করুন',
  by: 'দ্বারা',
  share: 'ভাগ',
  others: 'অন্যান্য',
  and: 'এবং',
  videoFor: '{name} এর জন্য',
  priceDetails: 'দাম বিশদ',
  costPrice: 'কেনা দাম',
  discount: 'ডিসকাউন্ট',
  walletUsed: 'ওয়ালেট ব্যবহৃত',
  totalAmount: 'সর্বমোট পরিমাণ',
  download: 'ডাউনলোড',
  starOfTheWeek: 'সপ্তাহের তারা',
  makeVideo: 'একটি ভিডিও তৈরি করুন',
  aboutMe: 'আমার সম্পর্কে',
  photo: 'ছবি',
  Instructions: 'ভিডিও অনুরোধ করার নির্দেশাবলী',
  requestDetailInfo1: 'আপনার বার্তাটি স্পষ্টভাবে লিখুন যাতে আমরা এটি পড়তে পারি। যদি বার্তাটি পরিষ্কারভাবে না লেখা হয় তবে তা প্রত্যাখ্যান করা হবে।',
  requestDetailInfo2: 'যে কোনও রূপের নোংরা বা ভুল ভাষা ব্যবহার করা সেই ভিডিও অনুরোধটিকে প্রত্যাখ্যান করবে এবং ফলস্বরূপ অ্যাকাউন্টটি বন্ধ হয়ে যেতে পারে।',
  requestDetailInfo3: 'একটি ভিডিও বানাতে কি লিখবেন',
  requestDetailInfo4: 'আপনি যদি ভিডিওটি নিজের জন্য করতে চান তবে "নিজের জন্য" নির্বাচন করুন',
  requestDetailInfo5: 'আপনি যদি কারও জন্য ভিডিও বানাতে চান তবে "অন্য কারও জন্য" নির্বাচন করুন।',
  requestDetailInfo6: 'আপনি যদি জন্মদিনের শুভেচ্ছা নিয়ে একটি ভিডিও করতে চান তবে আপনি বার্তায় লিখতে পারেন। "আমার বন্ধু রমেশের জন্য একটি জন্মদিনের ভিডিও তৈরি করুন, যার বয়স আজ 25 বছর হবে। তাকে বলুন আমাদের বন্ধুত্বটি ভুলে যাবেন না এবং আমি আমাদের পুরানো দিনগুলি মিস করছি।"',
  createVideoDetail: 'ভিডিও তৈরি করো',
  createVideoDetailInfo: 'আমি ভিডিও বার্তাটি "নিজের জন্য" করতে চাই',
  createVideoDetailInfo1: 'আমি ভিডিও বার্তাটি "অন্য কারও জন্য" বানাতে চাই',
  createVideoDetailInfo2: 'ভিডিওটি তৈরি করার নাম দিন',
  createVideoDetailInfo3: 'এখানে পুরো নাম লিখুন',
  createVideoDetailInfo4: 'আমার কাছে {name} এর জন্য একটি বার্তা রয়েছে}',
  createVideoDetailInfo5: 'আপনার সম্পূর্ণ বার্তা এখানে লিখুন',
  createVideoDetailInfo6: 'আপনি যদি জন্মদিনের শুভেচ্ছা নিয়ে একটি ভিডিও করতে চান তবে আপনি বার্তায় লিখতে পারেন। "আমার বন্ধু রমেশের জন্য একটি জন্মদিনের ভিডিও তৈরি করুন, যার বয়স আজ 25 বছর হবে। তাকে বলুন আমাদের বন্ধুত্বটি ভুলে যাবেন না এবং আমি আমাদের পুরানো দিনগুলি মিস করছি।"',
  createVideoDetailInfo7: 'আপনার পুরো নাম দিন',
  requestVideo: 'ভিডিওর অনুরোধ রুপী {priceTxt}',
  videos: 'ভিডিও',
  home: 'বাড়ি',
  profile: 'প্রোফাইল',
  orders: 'অর্ডার',
  celebrity: 'কীর্তি',
  celebrities: 'সেলিব্রেটি',
  notifications: 'বিজ্ঞপ্তিগুলি',
  likes: 'পছন্দ',
  comments: 'মন্তব্য',
  posted: '{post} পোস্ট করা হয়েছে',
  validationError: 'সমস্ত ডিলেটগুলি পূরণ করুন',
  validationErrorChar10: 'বার্তায় ন্যূনতম 10 টি অক্ষর থাকা আবশ্যক',
  validationErrorChar3: 'পূর্ণরূপে ন্যূনতম 3 টি অক্ষর থাকা আবশ্যক',
  commentsError: 'একটি মন্তব্য লিখুন',
  apiError: 'কিছু ভুল হয়েছে! আবার চেষ্টা কর',
  readMore: 'আরও পড়ুন',
  noComments: 'কোন মন্তব্য নেই',
  noVideo: 'কোনো ভিডিও নাই',
  noPhoto: 'কোনও ছবি নেই',
  createVideoButton: 'ভিডিও তৈরি করুন',
  noOrdersYet: 'কোনও ভিডিও অর্ডার করা হয়নি',
  noOrders: 'কোন আদেশ নেই',
  noNotifications: 'বিজ্ঞপ্তি নেই',
  pending: 'বিচারাধীন',
  approved: 'অনুমোদিত',
  incomplete: 'অসম্পূর্ণ',
  reject: 'প্রত্যাখ্যান',
  video: 'ভিডিও',
  rejectMessage: 'বার্তা প্রত্যাখ্যান করুন',
  writeComment: 'একটি মন্তব্য লিখুন ...',
  buyNow: 'এখন কেন',
  seeAll: 'সমস্ত দেখুন',
  congratulation: 'অভিনন্দন',
  congratulationMessage: 'আপনার প্রদান জমা দেওয়া হয়েছে এবং ভিডিও অনুরোধ প্রেরণ করা হয়েছে',
  userNotFound:'ব্যবহারকারী খুঁজে পাওয়া যায় না'
}
