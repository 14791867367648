export default {
  createdVideoFor: '<b>{celebrity} ద్వారా {name}</b> కోసం వీడియో చేయండి',
  by: 'ద్వారా',
  share: 'వాటా',
  others: 'ఇతరులు',
  and: 'మరియు',
  videoFor: '{name} కోసం',
  priceDetails: 'ధర వివరాలు',
  costPrice: 'ఖర్చు ధర',
  discount: 'డిస్కౌంట్',
  walletUsed: 'వాలెట్ ఉపయోగించబడింది',
  totalAmount: 'మొత్తం మొత్తం',
  download: 'డౌన్లోడ్',
  starOfTheWeek: 'వారపు నక్షత్రం',
  makeVideo: 'వీడియో చేయండి',
  aboutMe: 'నా గురించి',
  photo: 'ఫోటో',
  Instructions: 'వీడియోను అభ్యర్థించడానికి సూచనలు',
  requestDetailInfo1: 'మీ సందేశాన్ని స్పష్టంగా వ్రాయండి, తద్వారా మేము దానిని చదవగలం. సందేశం స్పష్టంగా వ్రాయకపోతే, అది తిరస్కరించబడుతుంది.',
  requestDetailInfo2: 'ఏదైనా మురికి లేదా తప్పు భాషను ఉపయోగించడం ఆ వీడియో అభ్యర్థనను తిరస్కరిస్తుంది మరియు ఫలితంగా ఖాతా మూసివేయబడుతుంది.',
  requestDetailInfo3: 'వీడియో చేయడానికి ఏమి రాయాలి',
  requestDetailInfo4: 'మీరు మీ కోసం వీడియోను తయారు చేయాలనుకుంటే, "మీ కోసం" ఎంచుకోండి',
  requestDetailInfo5: 'మీరు వేరొకరి కోసం వీడియో చేయాలనుకుంటే, "మరొకరి కోసం" ఎంచుకోండి.',
  requestDetailInfo6: 'మీరు పుట్టినరోజు శుభాకాంక్షల వీడియో చేయాలనుకుంటే, మీరు సందేశంలో వ్రాయవచ్చు. "ఈ రోజు 25 సంవత్సరాలు నిండిన నా స్నేహితుడు రమేష్ కోసం పుట్టినరోజు వీడియోను తయారు చేయండి. మా స్నేహాన్ని మరచిపోవద్దని అతనికి చెప్పండి మరియు నేను మా పాత రోజులను కోల్పోతాను."',
  createVideoDetail: 'వీడియో చేయండి',
  createVideoDetailInfo: 'నేను వీడియో సందేశాన్ని "నా కోసం" చేయాలనుకుంటున్నాను',
  createVideoDetailInfo1: 'నేను వీడియో సందేశాన్ని "వేరొకరి కోసం" చేయాలనుకుంటున్నాను',
  createVideoDetailInfo2: 'చేయవలసిన వీడియోకు పేరు పెట్టండి',
  createVideoDetailInfo3: 'పూర్తి పేరును ఇక్కడ నమోదు చేయండి',
  createVideoDetailInfo4: 'నాకు {name} కోసం సందేశం ఉంది.',
  createVideoDetailInfo5: 'మీ పూర్తి సందేశాన్ని ఇక్కడ వ్రాయండి',
  createVideoDetailInfo6: 'మీరు పుట్టినరోజు శుభాకాంక్షల వీడియో చేయాలనుకుంటే, మీరు సందేశంలో వ్రాయవచ్చు. "ఈ రోజు 25 సంవత్సరాలు నిండిన నా స్నేహితుడు రమేష్ కోసం పుట్టినరోజు వీడియోను తయారు చేయండి. మా స్నేహాన్ని మరచిపోవద్దని అతనికి చెప్పండి మరియు నేను మా పాత రోజులను కోల్పోతాను."',
  createVideoDetailInfo7: 'మీ పూర్తి పేరు ఇవ్వండి',
  requestVideo: 'వీడియోను అభ్యర్థించండి. {priceTxt}',
  videos: 'వీడియోలు',
  home: 'హోమ్',
  profile: 'ప్రొఫైల్',
  orders: 'ఆదేశాలు',
  celebrity: 'ప్రముఖ',
  celebrities: 'ప్రముఖులు',
  notifications: 'ప్రకటనలు',
  likes: 'ఇష్టాలు',
  comments: 'వ్యాఖ్యలు',
  posted: 'పోస్ట్ చేయబడింది {post}',
  validationError: 'దయచేసి అన్ని డీటిల్స్ నింపండి',
  validationErrorChar10: 'సందేశానికి కనీసం 10 అక్షరాలు అవసరం',
  validationErrorChar3: 'పూర్తి పేరుకు కనీసం 3 అక్షరాలు అవసరం',
  commentsError: 'దయచేసి ఒక వ్యాఖ్య రాయండి',
  apiError: 'ఎక్కడో తేడ జరిగింది! మళ్ళీ ప్రయత్నించండి',
  readMore: 'ఇంకా చదవండి',
  noComments: 'వ్యాఖ్యలు లేవు',
  noVideo: 'వీడియో లేదు',
  noPhoto: 'ఫోటో లేదు',
  createVideoButton: 'వీడియోను సృష్టించండి',
  noOrdersYet: 'ఇంకా వీడియోను ఆర్డర్ చేయలేదు',
  noOrders: 'ఆదేశాలు లేవు',
  noNotifications: 'నోటిఫికేషన్‌లు లేవు',
  pending: 'పెండింగ్',
  approved: 'ఆమోదించబడింది',
  incomplete: 'అసంపూర్ణ',
  reject: 'తిరస్కరించు',
  video: 'వీడియో',
  rejectMessage: 'సందేశాన్ని తిరస్కరించండి',
  writeComment: 'వ్యాఖ్య రాయండి ...',
  buyNow: 'ఇప్పుడే కొనండి',
  seeAll: 'అన్నింటిని చూడు',
  congratulation: 'అభినందనలు',
  congratulationMessage: 'మీ చెల్లింపు సమర్పించబడింది మరియు వీడియో అభ్యర్థన పంపబడింది',
  userNotFound:'వినియోగదారుడు కనపడలేదు'

}
