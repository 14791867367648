export default {
  createdVideoFor: '<b>{celebrity} ஆல் {name}</b> க்கான வீடியோவை உருவாக்கவும்',
  by: 'மூலம்',
  share: 'பகிர்',
  others: 'மற்றவைகள்',
  and: 'மற்றும்',
  videoFor: '{name} க்கு',
  priceDetails: 'விலை விவரங்கள்',
  costPrice: 'செலவு விலை',
  discount: 'தள்ளுபடி',
  walletUsed: 'வாலட் பயன்படுத்தப்பட்டது',
  totalAmount: 'மொத்த தொகை',
  download: 'பதிவிறக்க',
  starOfTheWeek: 'வாரத்தின் நட்சத்திரம்',
  makeVideo: 'ஒரு வீடியோவை உருவாக்கவும்',
  aboutMe: 'என்னை பற்றி',
  photo: 'புகைப்பட',
  Instructions: 'வீடியோவைக் கோருவதற்கான வழிமுறைகள்',
  requestDetailInfo1: 'உங்கள் செய்தியை தெளிவாக எழுதுங்கள், இதன் மூலம் நாங்கள் அதைப் படிக்க முடியும். செய்தி தெளிவாக எழுதப்படவில்லை என்றால், அது நிராகரிக்கப்படும்.',
  requestDetailInfo2: 'எந்தவொரு அழுக்கு அல்லது தவறான மொழியையும் பயன்படுத்துவது அந்த வீடியோ கோரிக்கையை நிராகரிக்கும், இதன் விளைவாக கணக்கு மூடப்படலாம்.',
  requestDetailInfo3: 'வீடியோ தயாரிக்க என்ன எழுத வேண்டும்',
  requestDetailInfo4: 'வீடியோவை நீங்களே உருவாக்க விரும்பினால், "உங்களுக்காக" என்பதைத் தேர்ந்தெடுக்கவும்',
  requestDetailInfo5: 'நீங்கள் வேறொருவருக்காக வீடியோவை உருவாக்க விரும்பினால், "வேறொருவருக்காக" என்பதைத் தேர்ந்தெடுக்கவும்.',
  requestDetailInfo6: 'பிறந்தநாள் வாழ்த்துக்களின் வீடியோவை நீங்கள் உருவாக்க விரும்பினால், நீங்கள் செய்தியில் எழுதலாம். "இன்று 25 வயதாகும் என் நண்பர் ரமேஷுக்கு பிறந்தநாள் வீடியோவை உருவாக்குங்கள். எங்கள் நட்பை மறந்துவிடாதீர்கள் என்று சொல்லுங்கள், எங்கள் பழைய நாட்களை நான் இழக்கிறேன்."',
  createVideoDetail: 'வீடியோவை உருவாக்கவும்',
  createVideoDetailInfo: 'வீடியோ செய்தியை "எனக்காக" உருவாக்க விரும்புகிறேன்',
  createVideoDetailInfo1: 'வீடியோ செய்தியை "வேறொருவருக்காக" உருவாக்க விரும்புகிறேன்',
  createVideoDetailInfo2: 'செய்ய வேண்டிய வீடியோவுக்கு பெயரிடுங்கள்',
  createVideoDetailInfo3: 'முழு பெயரை இங்கே உள்ளிடவும்',
  createVideoDetailInfo4: 'எனக்கு {name} க்கு ஒரு செய்தி உள்ளது.',
  createVideoDetailInfo5: 'உங்கள் முழு செய்தியையும் இங்கே எழுதுங்கள்',
  createVideoDetailInfo6: 'பிறந்தநாள் வாழ்த்துக்களின் வீடியோவை நீங்கள் உருவாக்க விரும்பினால், நீங்கள் செய்தியில் எழுதலாம். "இன்று 25 வயதாகும் என் நண்பர் ரமேஷுக்கு பிறந்தநாள் வீடியோவை உருவாக்குங்கள். எங்கள் நட்பை மறந்துவிடாதீர்கள் என்று சொல்லுங்கள், எங்கள் பழைய நாட்களை நான் இழக்கிறேன்."',
  createVideoDetailInfo7: 'உங்கள் முழு பெயரைக் கொடுங்கள்',
  requestVideo: 'வீடியோவைக் கோருங்கள்.{priceTxt}',
  videos: 'வீடியோக்கள்',
  home: 'வீடு',
  profile: 'பதிவு செய்தது',
  orders: 'ஆணைகள்',
  celebrity: 'பிரபல',
  celebrities: 'பிரபலங்கள்',
  notifications: 'அறிவிப்புகள்',
  likes: 'தோட்',
  comments: 'கருத்துக்கள்',
  posted: 'வெளியிட்டது {post}',
  validationError: 'எல்லா டீட்டில்களையும் நிரப்பவும்',
  validationErrorChar10: 'செய்தியில் குறைந்தபட்சம் 10 எழுத்துக்கள் தேவை',
  validationErrorChar3: 'முழுப்பெயரில் குறைந்தபட்சம் 3 எழுத்துக்கள் தேவை',
  commentsError: 'தயவுசெய்து ஒரு கருத்தை எழுதுங்கள்',
  apiError: 'ஏதோ தவறு நடைபெற்றிருக்கிறது! மீண்டும் முயற்சி செய்',
  readMore: 'மேலும் வாசிக்க',
  noComments: 'கருத்துகள் இல்லை',
  noVideo: 'வீடியோ இல்லை',
  noPhoto: 'புகைப்படம் இல்லை',
  createVideoButton: 'வீடியோவை உருவாக்கவும்',
  noOrdersYet: 'எந்த வீடியோவும் இதுவரை ஆர்டர் செய்யப்படவில்லை',
  noOrders: 'ஆர்டர்கள் இல்லை',
  noNotifications: 'அறிவிப்புகள் இல்லை',
  pending: 'நிலுவையில்',
  approved: 'அங்கீகரிக்கப்பட்ட',
  incomplete: 'முழுமையற்ற',
  reject: 'நிராகரி',
  video: 'வீடியோ',
  rejectMessage: 'செய்தியை நிராகரிக்கவும்',
  writeComment: 'கருத்து எழுதுங்கள் ...',
  buyNow: 'இப்போது வாங்க',
  seeAll: 'அனைத்து பார்க்க',
  congratulation: 'வாழ்த்துக்கள்',
  congratulationMessage: 'உங்கள் கட்டணம் சமர்ப்பிக்கப்பட்டு வீடியோ கோரிக்கை அனுப்பப்பட்டுள்ளது',
  userNotFound:'பயனர் கிடைக்கவில்லை'
}
