export default {
  createdVideoFor: '<b>{celebrity} द्वारे {name}</b> व्हिडिओ तयार करा',
  by: 'द्वारा',
  share: 'सामायिक करा',
  others: 'इतर',
  and: 'आणि',
  videoFor: '{name} साठी',
  priceDetails: 'किंमत तपशील',
  costPrice: 'किंमत किंमत',
  discount: 'सवलत',
  walletUsed: 'वॉलेट वापरलेले',
  totalAmount: 'एकूण रक्कम',
  download: 'डाउनलोड करा',
  starOfTheWeek: 'आठवड्याचा स्टार',
  makeVideo: 'व्हिडिओ बनवा',
  aboutMe: 'माझ्याबद्दल',
  photo: 'छायाचित्र',
  Instructions: 'व्हिडिओ विनंती करण्यासाठी सूचना',
  requestDetailInfo1: 'आपला संदेश स्पष्टपणे लिहा जेणेकरून आम्ही ते वाचू शकू. संदेश स्पष्टपणे लिहिले नाही तर तो नाकारला जाईल.',
  requestDetailInfo2: 'कोणत्याही प्रकारची घाणेरडी किंवा चुकीची भाषा वापरल्याने ती व्हिडिओ विनंती नाकारली जाईल आणि परिणामी खाते बंद केले जाऊ शकते.',
  requestDetailInfo3: 'व्हिडिओ बनवण्यासाठी काय लिहावे',
  requestDetailInfo4: 'आपण स्वतःसाठी व्हिडिओ बनवू इच्छित असल्यास, "आपल्यासाठी" निवडा',
  requestDetailInfo5: 'आपण दुसर्‍यासाठी व्हिडिओ बनवू इच्छित असल्यास, "दुसर्‍या एखाद्यासाठी" निवडा.',
  requestDetailInfo6: 'आपण वाढदिवसाच्या शुभेच्छा व्हिडिओ बनवू इच्छित असाल तर आपण संदेशामध्ये लिहू शकता. "माझा मित्र रमेश याच्या वाढदिवसाचा व्हिडिओ बनवा, जो आज 25 वर्षाचा असेल. त्याला सांगा की आमची मैत्री विसरू नका आणि मला आमचे जुने दिवस चुकले."',
  createVideoDetail: 'व्हिडिओ बनवा',
  createVideoDetailInfo: 'मला व्हिडिओ संदेश "स्वत: साठी" बनवायचा आहे',
  createVideoDetailInfo1: 'मला "दुसर्‍यासाठी" व्हिडिओ संदेश बनवायचा आहे',
  createVideoDetailInfo2: 'बनवण्यासाठी असलेल्या व्हिडिओचे नाव द्या',
  createVideoDetailInfo3: 'येथे पूर्ण नाव प्रविष्ट करा',
  createVideoDetailInfo4: 'माझ्याकडे {name} साठी एक संदेश आहे.',
  createVideoDetailInfo5: 'आपला पूर्ण संदेश येथे लिहा',
  createVideoDetailInfo6: 'आपण वाढदिवसाच्या शुभेच्छा व्हिडिओ बनवू इच्छित असाल तर आपण संदेशामध्ये लिहू शकता. "माझा मित्र रमेश याच्या वाढदिवसाचा व्हिडिओ बनवा, जो आज 25 वर्षाचा असेल. त्याला सांगा की आमची मैत्री विसरू नका आणि मला आमचे जुने दिवस चुकले."',
  createVideoDetailInfo7: 'आपले पूर्ण नाव द्या',
  requestVideo: 'व्हिडिओची विनंती करा रु.{priceTxt}',
  videos: 'व्हिडिओ',
  home: 'मुख्यपृष्ठ',
  profile: 'प्रोफाइल',
  orders: 'आदेश',
  celebrity: 'सेलिब्रिटी',
  celebrities: 'सेलिब्रिटी',
  notifications: 'अधिसूचना',
  likes: 'आवडी',
  comments: 'टिप्पण्या',
  posted: '{post} वर पोस्ट केले',
  validationError: 'कृपया सर्व डीएटल भरा',
  validationErrorChar10: 'संदेशासाठी किमान 10 वर्ण आवश्यक आहेत',
  validationErrorChar3: 'पूर्ण नावामध्ये किमान 3 वर्ण आवश्यक आहेत',
  commentsError: 'कृपया एक टिप्पणी लिहा',
  apiError: 'काहीतरी चूक झाली! पुन्हा प्रयत्न करा',
  readMore: 'पुढे वाचा',
  noComments: 'कोणत्याही टिप्पण्या नाहीत',
  noVideo: 'व्हिडिओ नाही',
  noPhoto: 'फोटो नाही',
  createVideoButton: 'व्हिडिओ तयार करा',
  noOrdersYet: 'अद्याप कोणत्याही व्हिडिओचे आदेश दिले गेले नाहीत',
  noOrders: 'ऑर्डर नाहीत',
  noNotifications: 'सूचना नाहीत',
  pending: 'प्रलंबित',
  approved: 'मंजूर',
  incomplete: 'अपूर्ण',
  reject: 'नाकारणे',
  video: 'व्हिडिओ',
  rejectMessage: 'संदेश नाकारा',
  writeComment: 'एक टीप्पणि लिहा ...',
  buyNow: 'आता खरेदी करा',
  seeAll: 'सर्व पाहा',
  congratulation: 'अभिनंदन',
  congratulationMessage: 'आपले देयक सबमिट केले गेले आहे आणि व्हिडिओ विनंती पाठविली गेली आहे',
  userNotFound:'वापरकर्ता सापडला नाही'

}
