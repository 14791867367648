import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes:[ {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "about" */ '../views/Notifications.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
  },
  {
    path: '/video-comments/:id/:transId',
    name: 'VideoComments',
    component: () => import(/* webpackChunkName: "about" */ '@/components/VideoComments'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import(/* webpackChunkName: "about" */ '@/components/VideoListingCard'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/star',
    name: 'StarVideoPage',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Common/StarVideoPage'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/celebrities-detail/:id',
    name: 'celebritiesDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/components/Celebrity/CelebritiesDetail'),
    // meta: { transitionName: 'slide' }
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "about" */ '@/components/CreateVideo/Success'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/video-request/:id',
    name: 'VideoCreateInfo',
    component: () => import(/* webpackChunkName: "about" */ '@/components/CreateVideo/VideoCreateInfo'),
    meta: { transitionName: 'slide' },
  }
],
scrollBehavior(to, from, savedPosition) {
  return new Promise(resolve => {
  setTimeout(() => {
      if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
    }, 1000);
  });
}
})



export default router
