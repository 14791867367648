export default {
  createdVideoFor: '<b>{celebrity} द्वारा {name}</b> के लिए एक वीडियो बनाएं',
  by: 'द्वारा',
  share: 'शेयर',
  others: 'अन्य',
  and: 'और',
  videoFor: '{name} के लिए',
  priceDetails: 'मूल्य विवरण',
  costPrice: 'लागत मूल्य',
  discount: 'छूट',
  walletUsed: 'वॉलेट का इस्तेमाल किया',
  totalAmount: 'कुल रकम',
  download: 'डाउनलोड करें',
  starOfTheWeek: 'इस हफ्ते की स्टार',
  makeVideo: 'वीडियो बनवाये',
  aboutMe: 'मेरे बारे में',
  photo: 'फ़ोटो',
  Instructions: 'वीडियो रिक्वेस्ट करने के लिए निर्देश',
  requestDetailInfo1: 'अपने सन्देश को साफ़ साफ़ लिखे ताकि हम उसे पढ़ सके| अगर सन्देश साफ़ साफ़ नहीं लखा हुआ है तो वो अस्वीकार कर दिया जाएगा|',
  requestDetailInfo2: 'किसी भी तरीके की गन्दी या गलत भाषा का उपयोग करने पर उस वीडियो रिक्वेस्ट को अस्वीकार कर दिया जाएगा और परिडाम सवरूप खाता बंद हो सकता है|',
  requestDetailInfo3: 'वीडियो बनाने के लिए क्या लिखे ',
  requestDetailInfo4: 'अगर आप वीडियो अपने लिए बनवाना चाहते है तो "खुद के लिए" को चयनित करे',
  requestDetailInfo5: 'अगर आप वीडियो किसी और के लिए बनवाना चाहते है तो "किसी और के लिए" को चयनित करे',
  requestDetailInfo6: 'यदि आप जनदिन की बधाई का वीडियो बनवाना चाहते है तो आप सन्देश में लिख सकते है की| "मेरे दोस्त रमेश के लिए जन्मदिन का वीडियो बनाये, जो आज 25 साल का हो जाएगा| उसे बताये की हमारी दोस्ती को न भूले और मुझे हमारे पुराने दिनों की बहुत याद आती है|"',
  createVideoDetail: 'वीडियो बनवाना है ',
  createVideoDetailInfo: 'मुझे वीडियो सन्देश “खुद के लिए” बनवाना है ',
  createVideoDetailInfo1: 'मुझे वीडियो सन्देश “किसी और के लिए” बनवाना है',
  createVideoDetailInfo2: 'जिसके लिए वीडियो बनवाना है उसका नाम बताये ',
  createVideoDetailInfo3: 'यहाँ पूरा नाम लिखे',
  createVideoDetailInfo4: '{name} के लिए मेरा सन्देश है|',
  createVideoDetailInfo5: 'यहाँ अपना पूरा सन्देश लिखे',
  createVideoDetailInfo6: 'यदि आप जनदिन की बधाई का वीडियो बनवाना चाहते है तो आप सन्देश में लिख सकते है की| "मेरे दोस्त रमेश के लिए जन्मदिन का वीडियो बनाये, जो आज 25 साल का हो जाएगा| उसे बताये की हमारी दोस्ती को न भूले और मुझे हमारे पुराने दिनों की बहुत याद आती है|"',
  createVideoDetailInfo7: 'अपना पूरा नाम बताये',
  requestVideo: 'वीडियो रिक्वेस्ट करे रु {priceTxt}',
  videos: 'वीडियो',
  home: 'होम ',
  profile: 'प्रोफ़ाइल',
  orders: 'ऑर्डर्स',
  celebrity: 'प्रसिद्ध व्यक्ति',
  celebrities: 'सेलिब्रिटीज',
  notifications: 'सूचनाएं',
  likes: 'लाइक्स ',
  comments: 'कॉमेंट्स',
  posted: '{post} पर पोस्ट किया गया',
  validationError: 'कृपया सभी विवरण भरें',
  validationErrorChar10: 'संदेश में कम से कम 10 अक्षर होने चाहिए',
  validationErrorChar3: 'पूरा नाम में कम से कम 3 अक्षर होने चाहिए',
  commentsError: 'कृपया एक कॉमेंट लिखें',
  apiError: 'कुछ गलत हो गया! पुनः प्रयास करें',
  readMore: 'और पढ़ें',
  noComments: 'कोई कॉमेंट नहीं',
  noVideo: 'कोई विडियो नहीं',
  noPhoto: 'कोई फ़ोटो नहीं',
  createVideoButton: 'वीडियो बनाएं',
  noOrdersYet: 'अभी तक किसी भी वीडियो का ऑर्डर नहीं दिया गया है',
  noOrders: 'कोई ऑर्डर नहीं',
  noNotifications: 'कोई सूचनाएं नहीं',
  pending: 'पैंडिंग',
  approved: 'स्वीकार किया हुआ',
  incomplete: 'अधूरा है',
  reject: 'अस्वीकार',
  video: 'वीडियो',
  rejectMessage: 'संदेश अस्वीकार करें',
  writeComment: 'कॉमेंट्स लिखें ...',
  buyNow: 'अभी खरीदें',
  seeAll: 'सभी देखें',
  congratulation: 'बधाई हो ',
  congratulationMessage: 'आपकी पेमेंट हो गई है और वीडियो की रिक्वेस्ट भेज दी गई है',
  userNotFound:'उपयोगकर्ता नहीं मिला'

}
