export default {
  createdVideoFor: '<b>{celebrity} ബി  {name} </b> എന്നതിനായി ഒരു വീഡിയോ നിർമ്മിക്കുക',
  by: 'എഴുതിയത്',
  share: 'പങ്കിടുക',
  others: 'മറ്റുള്ളവർ',
  and: 'ഒപ്പം',
  videoFor: '{name} માટે',
  priceDetails: 'വില വിശദാംശങ്ങൾ',
  costPrice: 'വില വില',
  discount: 'കിഴിവ്',
  walletUsed: 'വാലറ്റ് ഉപയോഗിച്ചു',
  totalAmount: 'മൊത്തം തുക',
  download: 'ഡൗൺലോഡ്',
  starOfTheWeek: 'ആഴ്ചയിലെ നക്ഷത്രം',
  makeVideo: 'ഒരു വീഡിയോ നിർമ്മിക്കുക',
  aboutMe: 'എന്നെ പറ്റി',
  photo: 'ഫോട്ടോ',
  Instructions: 'വീഡിയോ അഭ്യർത്ഥിക്കുന്നതിനുള്ള നിർദ്ദേശങ്ങൾ',
  requestDetailInfo1: 'നിങ്ങളുടെ സന്ദേശം വ്യക്തമായി എഴുതുക, അതുവഴി ഞങ്ങൾക്ക് അത് വായിക്കാൻ കഴിയും. സന്ദേശം വ്യക്തമായി എഴുതിയിട്ടില്ലെങ്കിൽ, അത് നിരസിക്കപ്പെടും.',
  requestDetailInfo2: 'ഏതെങ്കിലും തരത്തിലുള്ള വൃത്തികെട്ട അല്ലെങ്കിൽ തെറ്റായ ഭാഷ ഉപയോഗിക്കുന്നത് ആ വീഡിയോ അഭ്യർത്ഥന നിരസിക്കുകയും ഫലമായി അക്കൗണ്ട് അടയ്ക്കുകയും ചെയ്യാം.',
  requestDetailInfo3: 'ഒരു വീഡിയോ നിർമ്മിക്കാൻ എന്താണ് എഴുതേണ്ടത്',
  requestDetailInfo4: 'നിങ്ങൾക്ക് വീഡിയോ നിർമ്മിക്കണമെങ്കിൽ, "നിങ്ങൾക്കായി" തിരഞ്ഞെടുക്കുക',
  requestDetailInfo5: 'നിങ്ങൾക്ക് മറ്റൊരാൾക്കായി വീഡിയോ നിർമ്മിക്കണമെങ്കിൽ, "മറ്റൊരാൾക്കായി" തിരഞ്ഞെടുക്കുക.',
  requestDetailInfo6: 'ജന്മദിനാശംസകളുടെ ഒരു വീഡിയോ നിർമ്മിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുവെങ്കിൽ, നിങ്ങൾക്ക് സന്ദേശത്തിൽ എഴുതാം. "എന്റെ സുഹൃത്ത് രമേശിന് ഇന്ന് 25 വയസ്സ് തികയുന്ന ഒരു ജന്മദിന വീഡിയോ ഉണ്ടാക്കുക. ഞങ്ങളുടെ സൗഹൃദം മറക്കരുതെന്ന് അവനോട് പറയുക, ഞങ്ങളുടെ പഴയ ദിവസങ്ങൾ എനിക്ക് നഷ്ടമായി."',
  createVideoDetail: 'വീഡിയോ നിർമ്മിക്കുക',
  createVideoDetailInfo: 'വീഡിയോ സന്ദേശം "എനിക്കായി" ആക്കാൻ ഞാൻ ആഗ്രഹിക്കുന്നു',
  createVideoDetailInfo1: 'വീഡിയോ സന്ദേശം "മറ്റൊരാൾക്ക്" ആക്കാൻ ഞാൻ ആഗ്രഹിക്കുന്നു',
  createVideoDetailInfo2: 'നിർമ്മിക്കേണ്ട വീഡിയോയ്ക്ക് പേര് നൽകുക',
  createVideoDetailInfo3: 'മുഴുവൻ പേര് ഇവിടെ നൽകുക',
  createVideoDetailInfo4: 'എനിക്ക് {name} എന്നതിനായി ഒരു സന്ദേശമുണ്ട്.',
  createVideoDetailInfo5: 'നിങ്ങളുടെ മുഴുവൻ സന്ദേശവും ഇവിടെ എഴുതുക',
  createVideoDetailInfo6: 'ജന്മദിനാശംസകളുടെ ഒരു വീഡിയോ നിർമ്മിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുവെങ്കിൽ, നിങ്ങൾക്ക് സന്ദേശത്തിൽ എഴുതാം. "എന്റെ സുഹൃത്ത് രമേശിന് ഇന്ന് 25 വയസ്സ് തികയുന്ന ഒരു ജന്മദിന വീഡിയോ ഉണ്ടാക്കുക. ഞങ്ങളുടെ സൗഹൃദം മറക്കരുതെന്ന് അവനോട് പറയുക, ഞങ്ങളുടെ പഴയ ദിവസങ്ങൾ എനിക്ക് നഷ്ടമായി."',
  createVideoDetailInfo7: 'നിങ്ങളുടെ മുഴുവൻ പേര് നൽകുക',
  requestVideo: 'വീഡിയോ അഭ്യർത്ഥിക്കുക രൂപ. {priceTxt}',
  videos: 'വീഡിയോകൾ',
  home: 'വീട്',
  profile: 'പ്രൊഫൈൽ',
  orders: 'ഓർഡറുകൾ',
  celebrity: 'സെലിബ്രിറ്റി',
  celebrities: 'സെലിബ്രിറ്റികൾ',
  notifications: 'അറിയിപ്പുകൾ',
  likes: 'ഇഷ്‌ടങ്ങൾ',
  comments: 'അഭിപ്രായങ്ങൾ',
  posted: 'പോസ്റ്റ് ചെയ്തത് {post}',
  validationError: 'എല്ലാ ഡീറ്റിലുകളും പൂരിപ്പിക്കുക',
  validationErrorChar10: 'സന്ദേശത്തിന് കുറഞ്ഞത് 10 പ്രതീകങ്ങൾ ആവശ്യമാണ്',
  validationErrorChar3: 'പൂർണ്ണനാമത്തിൽ കുറഞ്ഞത് 3 പ്രതീകങ്ങൾ ആവശ്യമാണ്',
  commentsError: 'ദയവായി ഒരു അഭിപ്രായം എഴുതുക',
  apiError: 'എന്തോ കുഴപ്പം സംഭവിച്ചു! വീണ്ടും ശ്രമിക്ക്',
  readMore: 'കൂടുതല് വായിക്കുക',
  noComments: 'അഭിപ്രായങ്ങളൊന്നും ഇല്ല',
  noVideo: 'വീഡിയോ ഇല്ല',
  noPhoto: 'ഫോട്ടോയില്ല',
  createVideoButton: 'വീഡിയോ സൃഷ്ടിക്കുക',
  noOrdersYet: 'ഒരു വീഡിയോയും ഇതുവരെ ഓർഡർ ചെയ്തിട്ടില്ല',
  noOrders: 'ഓർഡറുകളൊന്നുമില്ല',
  noNotifications: 'അറിയിപ്പുകളൊന്നുമില്ല',
  pending: 'ശേഷിക്കുന്നു',
  approved: 'അംഗീകരിച്ചു',
  incomplete: 'അപൂർണ്ണമാണ്',
  reject: 'നിരസിക്കുക',
  video: 'വീഡിയോ',
  rejectMessage: 'സന്ദേശം നിരസിക്കുക',
  writeComment: 'ഒരു അഭിപ്രായം എഴുതുക ...',
  buyNow: 'ഇപ്പോൾ വാങ്ങുക',
  seeAll: 'എല്ലാം കാണൂಾ',
  congratulation: 'അഭിനന്ദനങ്ങൾ',
  congratulationMessage: 'നിങ്ങളുടെ പേയ്‌മെന്റ് സമർപ്പിക്കുകയും വീഡിയോ അഭ്യർത്ഥന അയയ്‌ക്കുകയും ചെയ്‌തു',
  userNotFound:'ഉപയോക്താവിനെ കണ്ടെത്തിയില്ല'

}
