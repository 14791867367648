<template>
  <div class="celebrities-listings">
    <div class="star-cards-block"  v-if="celebrityData.length">
      <h2>{{$t('starOfTheWeek')}}</h2>
      <div class="star-card">
        <div class="star-card-img">
          <img
            :src="promotedData.profile_pic"
            alt
          />
        </div>
        <div class="star-card-content">
          <div class="card-title">
            <h4>{{promotedData.name}}</h4>
            <img src="../assets/images/small-logo.png" alt />
          </div>
          <span class="card-profession">{{promotedData.profession}}</span>
          <p>{{promotedData.about}}</p>
          <span class="create-btn" @click="requestedVideo(promotedData.id)">{{$t('makeVideo')}}</span>
        </div>
      </div>
    </div>
    <CelebrityCard :dataValue="celebrityData"  />
  </div>
</template>

<script>
import '../assets/scss/create-video-listing.scss'
import CelebrityCard from './Common/CelebrityCard'
import { mapGetters } from 'vuex'
export default {
  name: 'CelebrityListing',
  components: {
    CelebrityCard
  },
  props: {
    celebrity: {
      type: Array,
      required: true
    },
    dataValue: {
      type: Object
    }
  },
  data () {
    return {
      celebrityData: [],
      promotedData: {}
    }
  },
  watch: {
    celebrity () {
      if (this.celebrity) {
        this.celebrityData = this.celebrity
      }
    },
    dataValue () {
      if (this.dataValue) {
        this.promotedData = this.dataValue
      }
    }
  },
  computed: {
    ...mapGetters(['promoted'])
  },
  mounted () {
    // for  celebs list
    if (this.celebrity) {
      this.celebrityData = this.celebrity
    }
    if (this.dataValue) {
      this.promotedData = this.dataValue
    } else {
      this.promotedData = this.promoted
    }
  },
  methods: {
    requestedVideo (value) {
      this.$router.push({ name: 'celebritiesDetail', params: { id: value } })
    }
  }
}
</script>
<style lang="scss" src="../assets/scss/video-listings.scss" scoped>
</style>
