<template>
  <div class="users-comments-wrapper">
    <header>
      <span class="goback-btn">
        <img @click="back" src="../assets/images/goback-arrow.svg" alt />
      </span>
      <div class="header-text">
        <span>{{$t('comments')}} ({{commentsCount}})</span>
      </div>
    </header>
    <ul class="user_UL">
      <li v-for="(list,index) in commentsData" :key="index">
        <span class="user-img">
          <img v-if="list.user_profile_pic !=''" :src="list.user_profile_pic" alt />
          <img v-else src="../assets/images/dummy.png" alt />
        </span>
        <div class="user-content">
          <h2>{{ChangeUserName(list.user_name)}}</h2>
          <span class="comment-date">{{ChangeFormat(list.user_comment_time)}}</span>
          <p v-if="readAllStatus !==index">{{textTruncate(list.user_comment,150)}}</p>
          <p v-if="readAllStatus ===index">{{list.user_comment}}</p>
          <span
            v-if="readAllStatus !==index && list.user_comment.length > 150"
            class="see-more"
            @click="realAll(index)"
          >{{$t('readMore')}}</span>
        </div>
      </li>
      <infinite-loading spinner="waveDots" @infinite="getComments">
        <div slot="no-more">......</div>
        <div class="no-data-status" slot="no-results">
          <span class="no-dt" v-if="commentsData.length > 0">{{$t('noComments')}}</span>
        </div>
      </infinite-loading>
    </ul>

    <Toaster :dataValue="toasterObj" />
    <form class="bottom-chat">
      <input type="text" :placeholder="this.$t('writeComment')" v-model="sendComment" />
      <span>
        <img @click="postComment()" src="../assets/images/plane.svg" alt />
      </span>
    </form>

    <div class="loader" v-if="loading">
      <img src="../assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import '../assets/scss/users-comments.scss'
import Toaster from './Common/Toaster'
import gaali from '@/utils/gaali'

export default {
  name: 'VideoComments',
  components: {
    Toaster
  },
  props: {
    popupData: {
      type: Object
    }
  },
  data () {
    return {
      commentsData: [],
      commentsCount: 0,
      sendComment: '',
      commentStartIndex: 0,
      userData: {},
      date: new Date(),
      loading: false,
      toasterObj: {},
      mediaId: null,
      mediaTransId: null,
      mediaIndex: null,
      readAllStatus: null
    }
  },
  watch: {
    popupData () {
      if (this.popupData) {
        this.mediaId = this.popupData.id
        this.mediaTransId = this.popupData.transId
        this.mediaIndex = this.popupData.index
      }
    }
  },
  mounted () {
    if (this.popupData) {
      this.mediaId = this.popupData.id
      this.mediaTransId = this.popupData.transId
      this.mediaIndex = this.popupData.index
    }
    window.scrollTo(0, 0)
  },
  methods: {
    realAll (index) {
      this.readAllStatus = index
    },
    ChangeFormat (value) {
      const date = new Date(value)
      return date.toDateString()
    },
    back () {
      let obj = { count: this.commentsData.length, index: this.mediaIndex }
      this.$emit('closeCommentsPopup', obj)
    },
    scrollToBottom () {
      let height =
        document.getElementsByClassName('user_UL')[0].scrollHeight + 20
      document.getElementsByClassName('user_UL')[0].scrollTo(0, height)
    },
    getComments ($state) {
      this.loading = true
      this.$store
        .dispatch({
          type: 'getComments',
          startIndex: this.commentStartIndex,
          mediaId: this.mediaId
        })
        .then(data => {
          if (data.data.comment_list.length > 0) {
            this.commentsData.push(...data.data.comment_list)
            this.userData = data.data.user
            this.commentsCount = this.commentsData.length
            this.commentStartIndex = this.commentStartIndex + 20
            $state && $state.loaded()
            this.loading = false
          } else {
            this.loading = false
            $state && $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    },
    textTruncate (str, length, ending) {
      if (ending == null) {
        ending = '...'
      }
      if (str.length > length) {
        return str.substring(0, length) + ending
      } else {
        return str
      }
    },
    ChangeUserName (list) {
      if (Number(list)) {
        const regex = /(?<!^).(?!$)/g
        return list.replace(regex, '*')
      } else {
        return list
      }
    },
    postComment () {
      if (!this.sendComment.trim()) {
        this.toasterObj = {
          status: true,
          msg: this.$t('commentsError'),
          color: '#000000'
        }
        return
      }
      const _comment = this.sendComment.toLowerCase()
      for (let _ in gaali) {
        if (_comment.indexOf(gaali[_].toLowerCase()) > -1) {
          this.toasterObj = {
            status: true,
            msg: 'Abusive comment not allowed',
            color: '#E51C24'
          }
          this.sendComment = ''
          return
        }
      }
      this.loading = true
      this.$store
        .dispatch({
          type: 'postComment',
          payload: {
            media_id: this.mediaId,
            comment: this.sendComment,
            trans_id: this.mediaTransId
          }
        })
        .then(data => {
          this.commentsCount = this.commentsCount + 1
          this.commentsData.unshift({
            user_name: this.userData.name,
            user_profile_pic: this.userData.profile_pic,
            user_comment_time: this.date.toDateString(),
            user_comment: this.sendComment
          })
          // this.scrollToBottom();
          window.scrollTo(0, 0)
          this.sendComment = ''
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.toasterObj = {
            status: true,
            msg: this.$t('apiError'),
            color: '#000000'
          }
        })
    }
  }
}
</script>

<style>
</style>
