<template>
  <footer class="main-footer">
    <ul>
      <li  v-bind:class="{'active': this.$route.path == '/'}"  @click="changeStatus(1)"  >
        <img src="../../assets/images/dark-home.svg" alt class="dark-icon" />
        <img src="../../assets/images/light-home.svg" alt class="light-icon" />
        <span>{{$t('home')}}</span>
      </li>
      <li  v-bind:class="{'active': this.$route.path == '/notifications'}" @click="changeStatus(2)">
        <img src="../../assets/images/dark-notification.svg" alt class="dark-icon" />
        <img src="../../assets/images/light-notification.svg" alt class="light-icon" />
        <span>{{$t('notifications')}}</span>
      </li>
      <li  v-bind:class="{'active': this.$route.path == '/profile'}"  @click="changeStatus(3)">
        <img src="../../assets/images/dark-profile.svg" alt class="dark-icon" />
        <img src="../../assets/images/light-profile.svg" alt class="light-icon" />
        <span>{{$t('profile')}}</span>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      active: 1
    }
  },
  watch: {},
  mounted () {},
  methods: {
    changeStatus (status) {
      this.active = status
      if (status == 1) {
        this.$router.push('/')
      } else if (status == 2) {
        this.$router.push('notifications')
      } else if (status == 3) {
        this.$router.push('profile')
      }
    }
  }
}
</script>
