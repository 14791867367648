<template>
        <div :id="id" class="flowplayer">
          <video  style="background-color:black" controls preload="auto">
            <!-- :src="video_link"-->
            <source :poster="poster" type="video/mp4" size="auto" />
            <!-- :src="video_link"-->
          </video>
        </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    video_link: null,
    poster: null,
    id: null
  },
  data () {
    return {
      popoverlayBg: true,
      canClose: false,
      flowplayer: null
    }
  },
  mounted () {

    this.initialVideo()
  },
  beforeDestroy () {
    document.body.classList.remove("videoBody");
     this.$data.flowplayer.pause()
  },
  methods: {
    videoCloseModel () {
      this.$data.flowplayer.pause()
      document.body.classList.remove("videoBody");
      this.$emit('closeVideoModel', false)
    },
    initialVideo () {
      const video_link = this.video_link
      var _this = this
      setTimeout(
        function (video_link, a) {
          var container = document.getElementById(a.id)
          a.$data.flowplayer = flowplayer(container, {
          //  key:key,
          // logo:"../static/images/db_watermark_video.png",
            loop: true,
            clip: {
              loop: true,
              sources: [
                {
                  type: 'video/mp4',
                  autoplay: true,

                  src: video_link
                }
              ]
            }
          })
          // debugger;
          a.$data.flowplayer.play()
        },
        50,
        video_link,
        _this
      )
    }
  }
}
</script>

<style scoped>
.videoOuter {
  position: fixed;
  width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  z-index: 11111;
  background: transparent;
  display: none;
  transform: translate(-50%, -50%);
}
.videoBody .popoverlayBg {
  display: block;
  z-index: 1111;
}

.videoBody .videoOuter {
  display: block;
}

.video-player-box {
  width: 90%;
  height: auto;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1111;
  padding: 5px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}
/*
#videoplaycnt video {
  max-width: 100vw;
  height: 100vh;
} */

/* .video-player-box video {
  height: 80vh;
} */

.video-player-box .closeModel {
  width: 24px;
  height: 24px;
  background: #00abcd;
  display: inline-block;
  position: absolute;
  left: auto;
  right: -8px;
  z-index: 1;
  border-radius: 100px;
  top: -9px;
  color: #fff;
  line-height: 25px;
  font-weight: bold;
  font-size: 0.75em;
  padding-left: 7px;
}

#videoplaycnt .flowplayer .fp-player a {
  display: none !important;
}

@media only screen and (max-width: 767px) and (min-width: 479px) {
  /* .videoOuter {
    position: fixed;
    width: 100%;
    height: 50%;
    left: 50%;
    top: 50%;
    z-index: 11111;
    background: transparent;
    display: none;
    transform: translate(-50%, -50%);
  }
  .video-player-box {
    width: 50%;
  } */
}
</style>
