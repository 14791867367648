import Vue from 'vue'
import Vuex from 'vuex'
import ApiCaller from '../utils/apicaller'
import constant from '../utils/constant'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allVideoListIndex: 0,
    allVideoList: [],
    allCelebrityListIndex: 0,
    allCelebrityList: [],
    promotedCelebrity: {},
    profileOrdersListIndex: 0,
    profileOrdersList: [],
    profileVideosListIndex: 0,
    profileVideosList: [],
    celebrityDetail: {},
    celebrityVideosIndex: 0,
    celebrityVideos: [],
    celebrityPhotosIndex: 0,
    celebrityPhotos: [],
    userProfileData: {},
    notificationData: [],
    promoted: {}
  },
  getters: {
    allVideoListIndex: state => state.allVideoListIndex,
    allVideoList: state => state.allVideoList,
    allCelebrityListIndex: state => state.allCelebrityListIndex,
    allCelebrityList: state => state.allCelebrityList,
    promotedCelebrity: state => state.promotedCelebrity,
    profileOrdersListIndex: state => state.profileOrdersListIndex,
    profileOrdersList: state => state.profileOrdersList,
    profileVideosListIndex: state => state.profileVideosListIndex,
    profileVideosList: state => state.profileVideosList,
    celebrityDetail: state => state.celebrityDetail,
    celebrityVideosIndex: state => state.celebrityVideosIndex,
    celebrityVideos: state => state.celebrityVideos,
    celebrityPhotosIndex: state => state.celebrityPhotosIndex,
    celebrityPhotos: state => state.celebrityPhotos,
    userProfileData: state => state.userProfileData,
    notificationIndex: state => state.notificationIndex,
    notificationData: state => state.notificationData,
    promoted: state => state.promoted

  },
  mutations: {
    saveAllVideoList(state, obj) {
      state.allVideoListIndex = obj.allVideoListIndex
      state.allVideoList = obj.allVideoList
    },
    saveAllCelebrityList(state, obj) {
      state.allCelebrityListIndex = obj.allCelebrityListIndex
      state.allCelebrityList = obj.allCelebrityList
      state.promotedCelebrity = obj.promotedCelebrity
    },
    saveCelebrityDetail(state, obj) {
      state.celebrityDetail = obj.celebrityDetail
    },
    saveCelebrityVideos(state, obj) {
      state.celebrityVideosIndex = obj.celebrityVideosIndex
      state.celebrityVideos = obj.celebrityVideos
    },
    saveCelebrityPhotos(state, obj) {
      state.celebrityPhotosIndex = obj.celebrityPhotosIndex
      state.celebrityPhotos = obj.celebrityPhotos
    },
    saveProfileOrdersList(state, obj) {
      state.profileOrdersListIndex = obj.profileOrdersListIndex
      state.profileOrdersList = obj.profileOrdersList
      state.userProfileData = obj.userProfileData
    },
    saveProfileOrdersListIndex(state, obj) {
      state.profileOrdersListIndex = obj.profileOrdersListIndex
    },
    saveProfileVideosList(state, obj) {
      state.profileVideosListIndex = obj.profileVideosListIndex
      state.profileVideosList = obj.profileVideosList
    },
    saveUserProfileData(state, obj) {
      state.userProfileData = obj.userProfileData
    },
    saveNotificationData(state, obj) {
      state.notificationData = obj.notificationData
      state.notificationIndex = obj.notificationIndex
    },
    savePromotedCeleb(state, obj) {
      state.promoted = obj.promoted
    }
  },
  actions: {
    getProduct({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL_MALL, '/getProductAds', 'get')
    },
    getVideo({ commit }, { requestId }) {
      return ApiCaller(constant.BASE_API_URL, '/requests/0/1?request_id=' + requestId, 'get')
    },
    getVideoList({ commit }, { startIndex, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/requests/' + startIndex + '/10?all=' + payload, 'get')
    },
    getCelebrityList({ commit }, { startIndex, endIndex, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/allcelebs/' + startIndex + '/20', 'get')
    },
    likeVideo({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/media/like', 'post', payload)
    },
    unlikeVideo({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/media/unlike', 'post', payload)
    },
    getComments({ commit }, { type, startIndex, mediaId }) {
      return ApiCaller(constant.BASE_API_URL, '/users/media/show_comment/' + startIndex + '/20?media_id=' + mediaId, 'get')
    },
    postComment({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/media/comment', 'post', payload)
    },
    Share({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/media/share_count', 'post', payload)
    },
    getProfile({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/profile', 'get')
    },
    getCelebrityDetail({ commit }, { id, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/celeb_about?celeb_id=' + id, 'get')
    },
    getCelebrityVideo({ commit }, { startIndex, id }) {
      return ApiCaller(constant.BASE_API_URL, '/requests/' + startIndex + '/10?celeb_id=' + id, 'get')
    },
    getCelebrityPhoto({ commit }, { id, startIndex, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/celeb_media/' + startIndex + '/20?celeb_id=' + id + '&type=Instagram', 'get')
    },
    getVideoOrder({ commit }, { startIndex, endIndex, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/responded/' + startIndex + '/10', 'get')
    },
    getOrderlist({ commit }, { startIndex }) {
      return ApiCaller(constant.BASE_API_URL, '/users/orders/' + startIndex + '/10', 'get')
    },
    checkUserVipStatus({commit}, celeb_id){
      return ApiCaller(constant.BASE_API_URL,`/check/vipuser?celeb_id=${celeb_id}`,  'get')
    },
    requestedVideo({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_API_URL, '/users/sendviprequest', 'post', payload)
    },
    requestedVideoPayment({ commit }, { type, payload }) {
      return ApiCaller(constant.BASE_PAYMENT_URL, '/payment', 'post', payload)
    },
    getNotifications({ commit }, { startIndex }) {
      return ApiCaller(constant.BASE_API_URL, '/users/notifications/' + startIndex + '/10', 'get')
    }
  }
})
